export enum AvailableValuesForParametersDropdown {
  operationStatus = "operationStatus",
  dhwPreferredTemp = "dhwPreferredTemp",//???NO DESIGN
  currentRoomTemperatureZ1 = "currentRoomTemperatureZ1",
  preferredRoomTempZ1 = "preferredRoomTempZ1",//???NO DESIGN
  currentRoomTemperatureZ2 = "currentRoomTemperatureZ2",
  preferredRoomTempZ2 = "preferredRoomTempZ2",//???NO DESIGN
  outdoorTemperature = "outdoorTemperature",
  //Cooling/Heating water temperature THC //NO PARAMETER
  dhwTankTemperatureTWD = "dhwTankTemperatureTWD",
  tv1Temperature = "tv1Temperature",
  tv2Temperature = "tv2Temperature",
  condenserOutletWaterTemperatureTUO = "condenserOutletWaterTemperatureTUO",
  currentSeason = "currentSeason",
  //FIRST COL
  condenserInletWaterTemperatureTUI = "condenserInletWaterTemperatureTUI",
  compressorSpeed = "compressorSpeed",
  requestedCompressorSpeed = "requestedCompressorSpeed",
  defrostMode = "defrostMode",
  waterPumpP0Status = "waterPumpP0Status",
  waterFlowRate = "waterFlowRate",
  waterPumpP1Status = "waterPumpP1Status",
  waterPumpP2Status = "waterPumpP2Status",
  //Anti-legionella (current state) //NO PARAMETER !!!
  quiteMode = "quiteMode",
  highPressurePd = "highPressurePd",
  //SECOND COL
  compressorDischargeTemperatureTD = "compressorDischargeTemperatureTD",
  lowPressurePs = "lowPressurePs",
  compressorSuctionTemperatureTS = "compressorSuctionTemperatureTS",
  condensingTemperatureTUP = "condensingTemperatureTUP",
  evaporatingTemperatureTP = "evaporatingTemperatureTP",
  eevOpeningP = "eevOpeningP",
  fanSpeed1 = "fanSpeed1",
  fanSpeed2 = "fanSpeed2",
  sgReadyStatus = "sgReadyStatus",//???NO DESIGN
  //Additional inline heating source status //??? 3 PARAMETERS FOR 1 PROPERTY  ???
  additionalInlineHeatingSource = "additionalInlineHeatingSource",
  additionalDhwHeatingSourceStatus = "additionalDhwHeatingSourceStatus",
  additionalBufferTankHeatingSourceStatus = "additionalBufferTankHeatingSourceStatus",
  outdoorUnitCurrent = "outdoorUnitCurrent",
  outdoorUnitVoltage = "outdoorUnitVoltage",
  vacationModeState = "vacationModeState",//???NO DESIGN

  //ON THE DESIGN ITS ONLY ONE VALUE FOR THIS PARAMS
  //REMOVED!!!
  // outdoorUnitElectricityCurrent1 = "outdoorUnitElectricityCurrent1",
  // outdoorUnitElectricityCurrent2 = "outdoorUnitElectricityCurrent2",
  // outdoorUnitElectricityCurrent3 = "outdoorUnitElectricityCurrent3",
  // outdoorUnitVoltage1 = "outdoorUnitVoltage1",
  // outdoorUnitVoltage2 = "outdoorUnitVoltage2",
  // outdoorUnitVoltage3 = "outdoorUnitVoltage3",
}

// currentSeason,
//   operationStatus,
//   outdoorTemperature,
//   currentRoomTemperatureZ1,
//   currentRoomTemperatureZ2,
//   tv1Temperature,
//   tv2Temperature,
//   tv1SetPointTemp,
//   tv2SetPointTemp,
//   preferredRoomTempZ1,
//   preferredRoomTempZ2,
//   dhwTankTemperatureTWD,
//   condenserOutletWaterTemperatureTUO,
//   condenserInletWaterTemperatureTUI,
//   setPointTHC,
//   waterPumpP0Status,
//   waterPumpP1Status,
//   waterPumpP2Status,
//   defrostMode,
//   compressorSpeed,
//   requestedCompressorSpeed,
//   highPressurePd,
//   compressorDischargeTemperatureTD,
//   lowPressurePs,
//   compressorSuctionTemperatureTS,
//   condensingTemperatureTUP,
//   evaporatingTemperatureTP,
//   eevOpeningP,
//   fanSpeed1,
//   fanSpeed2,
//   quietMode,
//   reducedModeState,
//   vacationModeState,
//   electricGridProtectionStatus,
//   additionalInlineHeatingSourceStage1,
//   additionalInlineHeatingSourceStage2,
//   additionalInlineHeatingSourceStage3,
//   additionalBufferTankHeatingSourceStatus,
//   additionalDhwHeatingSourceStatus,
//   waterFlowRate,
//   sgReadyStatus,
//   dhwPreferredTemp,
//   outdoorUnitCurrent,
//   outdoorUnitVoltage;

//    added
//1waterPumpP0Status
//2waterFlowRate
//3sgReadyStatus ???!!!: No design ????
//4   additionalInlineHeatingSourceStage1, ???IMPLEMENTATION
//   additionalInlineHeatingSourceStage2,
//   additionalInlineHeatingSourceStage3,
//5additionalDhwHeatingSourceStatus,
//6additionalBufferTankHeatingSourceStatus,
//7outdoorUnitCurrent,
//8outdoorUnitVoltage,
//9preferredRoomTempZ1,//No design??
//10preferredRoomTempZ2,//No design??
//11dhwPreferredTemp//No design??
//12vacationModeState

//total 12 - missing form confluence
//VacationMode - no parameter in confluense but exist in API???
//Still have some missed parameters according to the design and don't have design displays for some existing parameters!!!
