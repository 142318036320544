import { Box, Divider, InputLabel, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import MainTitle from "../MainTitle/MainTitle";
import DisabledField from "../DisabledField/DisabledField";
import TextFieldWithLabel from "../TextFieldWithLabel/TextFieldWithLabel";
import { IInstallationCommmissioningInfo, IInstallationDetailsData } from "../../InfoDetailsPage";
import AddressAutocompleteComponent from "../../../../../../components/AddressAutocompleteComponent/AddressAutocompleteComponent";
import OrganizationSection from "../OrganizationSection/OrganizationSection";
import { IInstallationDetailSharingInfo } from "../../../../../../store/services/models/installations/installationsCommon";
import { Edit01, Plus } from "untitledui-js-base";
import Badge from "../../../../../../components/Badge";
import AssignInstallationDialog, {
  AssignInstallationDialogRef,
} from "../../../../AssingInstallation/AssignInstallationDialog";
import { useLocation, useNavigate } from "react-router";
import AuthenticationAPI from "../../../../../../api/AuthenticationAPI";

export interface IInstallationDetailsColumnProps {
  installationUuid?: string;
  installationDetails: IInstallationDetailsData | undefined;
  setInstallationDetails: (fieldName: string, newValue: any) => void;
  commissioningInfo: IInstallationCommmissioningInfo | undefined;
  sharingInfo: IInstallationDetailSharingInfo | undefined;
  refetchInstallationDetails?: () => void;
}

export default function InstallationDetailsColumn({
  installationUuid,
  installationDetails,
  setInstallationDetails,
  commissioningInfo,
  sharingInfo,
  refetchInstallationDetails,
}: IInstallationDetailsColumnProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.infoDetailsPage.installationDetails",
  });
  const { t: tAssign } = useTranslation("cloud_ui", {
    keyPrefix: "pages.assignInstaller",
  });

  const assignInstallationDialogRef = useRef<AssignInstallationDialogRef>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const authApi = new AuthenticationAPI(navigate);
  const isSuperAdminLevel = location.pathname.includes("/admin/");
  const isShowResponsibleOrganizations =
    !!sharingInfo?.parentOrganization ||
    !!sharingInfo?.myOrganization ||
    !!sharingInfo?.childrenOrganizations ||
    (!!installationUuid && isSuperAdminLevel);

  const [hasUserInstallationShareAccess, setUserInstallationShareAccess] = useState<boolean>(false);

  useEffect(() => {
    authApi
      .hasRoles(["ADMIN", "INSTALLATION_SHARE"])
      .then((response) => {
        setUserInstallationShareAccess(response.data.value);
      })
      .catch((error) => {
        //TODO Stan: Show message?
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSelectOrganizationBtn = () => {
    if (!!installationUuid && hasUserInstallationShareAccess) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row", ml: theme.spacing.spacingXl }}>
          <Typography
            sx={{
              ...theme.typography[".text-lg-semibold"],
              color: theme.colors.colorsTextTextPrimary,
              mb: theme.spacing.spacingXl,
              mr: theme.spacing.spacingXs,
            }}
          >
            ↳
          </Typography>
          <Badge
            text={tAssign("selectBtn")}
            type="success"
            Icon={Plus}
            onClick={() => {
              assignInstallationDialogRef.current?.open({ installationUuid });
            }}
          />
        </Box>
      );
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        maxWidth: "610px",
        width: "50%",
        borderRight: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
          backgroundColor: theme.colors.colorsBackgroundBgSecondaryAlt,
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "4px",
          backgroundColor: "rgba(0,0,0,0.2)",
          minHeight: "24px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: theme.colors.colorsTextTextQuaternary,
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "415px",
          pr: theme.spacing.spacing3xl,
        }}
      >
        <MainTitle text={t("mainTitle")} sx={{ mb: theme.spacing.spacing2xl }} />
        <DisabledField
          label={t("fieldsLabels.connectivityId")}
          value={installationDetails?.connectivityId}
        />
        <TextFieldWithLabel
          value={installationDetails?.name || ""}
          setValue={(newValue) => setInstallationDetails("name", newValue)}
          label={t("fieldsLabels.name")}
          placeholder={t("fieldsPlaceholders.name")}
        />
        <Box sx={{ width: "100%", mb: theme.spacing.spacingLg }}>
          <InputLabel
            shrink
            sx={{
              ...theme.typography[".text-sm-medium"],
              color: theme.colors.colorsTextTextSecondary,
            }}
          >
            {t("fieldsLabels.address")}
          </InputLabel>
          <AddressAutocompleteComponent
            onAddressSelect={(address) => {
              setInstallationDetails("location", address);
            }}
            address={installationDetails?.location || ""}
            placeholder={`${t("fieldsPlaceholders.address")}`}
          />
        </Box>
        {commissioningInfo?.commissionedDate && (
          <DisabledField
            label={t("fieldsLabels.registrationDate")}
            value={commissioningInfo?.commissionedDate}
          />
        )}
        <DisabledField
          label={t("fieldsLabels.commissionedBy")}
          value={commissioningInfo?.commissioner || t("fieldsPlaceholders.notAvailable")}
        />
        {isShowResponsibleOrganizations && (
          <>
            <Divider
              sx={{
                mt: theme.spacing.spacing3xl,
                mb: theme.spacing.spacingXl,
                width: "100%",
                borderColor: theme.colors.colorsBorderBorderSecondary,
              }}
            />

            <MainTitle
              text={t("responsibleOrganizationsTitle")}
              sx={{ mb: theme.spacing.spacingXl }}
            />
          </>
        )}

        {
          <>
            {sharingInfo?.parentOrganization && (
              <OrganizationSection
                // title={t("responsibleOrganizations.parentOrganization")}
                name={sharingInfo.parentOrganization.name}
                phone={sharingInfo.parentOrganization.phone}
                address={sharingInfo.parentOrganization.address}
              />
            )}
            {sharingInfo?.myOrganization && (
              <Box>
                <OrganizationSection
                  // title={t("responsibleOrganizations.myOrganization")}
                  name={`${sharingInfo.myOrganization.name} (me)`}
                  phone={sharingInfo.myOrganization.phone}
                  address={sharingInfo.myOrganization.address}
                  showArrow={!!sharingInfo.parentOrganization}
                  paddingLeft={!!sharingInfo.parentOrganization ? "15px" : ""}
                />
              </Box>
            )}
            {sharingInfo?.childrenOrganizations && sharingInfo.childrenOrganizations.length > 0 ? (
              sharingInfo.childrenOrganizations.map((org, index) => (
                <Box key={`childOrg_${index}`}>
                  <OrganizationSection
                    // title={t("responsibleOrganizations.childrenOrganizations")}
                    name={org.name}
                    address={org.address}
                    phone={org.phone}
                    showArrow={!!sharingInfo.myOrganization}
                    paddingLeft="30px"
                  />
                </Box>
              ))
            ) : !!installationUuid && isSuperAdminLevel ? (
              <Badge
                text={tAssign("assignBtn")}
                type="warning"
                Icon={Edit01}
                onClick={() => {
                  assignInstallationDialogRef.current?.open({ installationUuid });
                }}
              />
            ) : (
              renderSelectOrganizationBtn()
            )}
          </>
        }
      </Box>
      {(isSuperAdminLevel || hasUserInstallationShareAccess) && (
        <AssignInstallationDialog
          ref={assignInstallationDialogRef}
          isSuperAdmin={isSuperAdminLevel && !sharingInfo?.myOrganization}
          onSuccess={refetchInstallationDetails}
          myOrganizationUuid={sharingInfo?.myOrganization?.uuid}
          installationName={installationDetails?.name}
        />
      )}
    </Box>
  );
}
