import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../../../store";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../store/services/installation";
import TabWrapper from "../../../../TabWrapper";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import {
  AdditionalSourcePriorityForDhwEnum,
  IInstallationSettingsByIdResponse,
  ISetDhwSettings,
  ISetInstallationSettings,
  ISetInstallerSettignsComissioningAdditionalDHWHeatingSource,
  ISetInstallerSettingsComissioningDHW,
  ISetOperationSettings,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import SettingsSwitcher from "../../../../components/SettingsSwitcher/SettingsSwitcher";
import { Box, Divider } from "@mui/material";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import {
  ActiveOrInactiveEnum,
  ActiveOrInactiveForDHWEnum,
  IInstallationBaseValueWithMeasurement,
  OnOrOffEnum,
} from "../../../../../../../../store/services/models/installations/installationsCommon";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import {
  baseFindDifferences,
  cleanValuesForRequest,
  isEmpty,
} from "../../../../helpers/findDifferences";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import { Tool02 } from "untitledui-js-base";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import DifferencesToShowInModal from "./DifferencesToShowInModal";
import NoParametersBlock from "../../../../NoParametersBlock";

export interface IInstallerDHWSettingsData {
  preferredTemp: IInstallationBaseValueWithMeasurement;
  deltaDeviationTemp: IInstallationBaseValueWithMeasurement;
  active: ActiveOrInactiveForDHWEnum;
  state: ActiveOrInactiveForDHWEnum;
  inputPower: IInstallationBaseValueWithMeasurement;
  additionalSourcePriorityForDhw: AdditionalSourcePriorityForDhwEnum;
}

export default function InstallerDHW({
  isInstallationOffline,
}: {
  isInstallationOffline?: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const [isPoolingIntervalEnabled, setIsPoolingIntervalEnabled] =
    useState<boolean>(true);

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery(
    {
      uuid: id!,
      isRealData: params.get("dummyData") === "false",
    },
    {
      pollingInterval: isPoolingIntervalEnabled ? 60000 : 0,
    }
  );

  const [setInstallationSettings] = useSetInstallationSettingsMutation();

  const [isFieldsDisabled, setIsFieldsDisabled] = useState<boolean>(
    !!isInstallationOffline
  );
  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);

  const [installerDHWSettingsData, setInstallerDHWSettingsData] =
    useState<IInstallerDHWSettingsData>();
  const [initialInstallerDHWSettingsData, setInitialInstallerDHWSettingsData] =
    useState<IInstallerDHWSettingsData>();

  const [initialSettings, setInitialSettings] =
    useState<IInstallationSettingsByIdResponse | null>(null);

  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [differencesToShow, setDifferencesToShow] = useState<any>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setIsFieldsDisabled(!!isInstallationOffline);
  }, [isInstallationOffline]);

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
      const data = {
        preferredTemp: settingsParameters.dhw?.preferredTemp,
        deltaDeviationTemp:
          settingsParameters.installerSettings?.commissioning?.dhw
            ?.deltaDeviationTemp,
        active:
          settingsParameters?.installerSettings?.commissioning?.dhw?.active,
        state:
          settingsParameters.installerSettings?.commissioning
            ?.additionalDhwHeatingSource?.state,
        inputPower:
          settingsParameters.installerSettings?.commissioning
            ?.additionalDhwHeatingSource?.inputPower,
        additionalSourcePriorityForDhw:
          settingsParameters?.installerSettings?.commissioning?.dhw
            ?.additionalSourcePriorityForDhw,
      };
      setInstallerDHWSettingsData(JSON.parse(JSON.stringify(data)));
      setInitialInstallerDHWSettingsData(JSON.parse(JSON.stringify(data)));
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (
      JSON.stringify(installerDHWSettingsData) !==
      JSON.stringify(initialInstallerDHWSettingsData)
    ) {
      setIsSomethingChanged(true);
      setIsPoolingIntervalEnabled(false);
    } else {
      setIsSomethingChanged(false);
      setIsPoolingIntervalEnabled(true);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [installerDHWSettingsData]);

  const updateInstallerDHWSettings = (field: string, value: any) => {
    const updatedSettings = { ...installerDHWSettingsData };
    (updatedSettings as any)[field] = value;
    setInstallerDHWSettingsData(updatedSettings as IInstallerDHWSettingsData);
  };

  const onDiscardChanges = () => {
    setInstallerDHWSettingsData(
      JSON.parse(JSON.stringify(initialInstallerDHWSettingsData))
    );
    setIsPoolingIntervalEnabled(true);
  };

  const onSaveChanges = () => {
    const differences = baseFindDifferences(
      initialInstallerDHWSettingsData!,
      installerDHWSettingsData!
    );
    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    let newDHWSettings: Partial<
      ISetDhwSettings &
        ISetInstallerSettignsComissioningAdditionalDHWHeatingSource &
        ISetOperationSettings &
        ISetInstallerSettingsComissioningDHW
    > = cleanValuesForRequest(differencesToShow);

    let result: any = {};

    if (newDHWSettings.preferredTemp) {
      result.dhw = {};

      if (newDHWSettings.preferredTemp) {
        result.dhw.preferredTemp = newDHWSettings.preferredTemp;
      }
    }

    if (
      newDHWSettings.state ||
      newDHWSettings.inputPower ||
      newDHWSettings.deltaDeviationTemp ||
      newDHWSettings.additionalSourcePriorityForDhw ||
      newDHWSettings.active
    ) {
      result.installerSettings = {
        commissioning: {},
      };

      if (newDHWSettings.state || newDHWSettings.inputPower) {
        result = {
          ...result,
          installerSettings: {
            ...result?.installerSettings,
            commissioning: {
              ...result?.installerSettings?.commissioning,
              additionalDhwHeatingSource: {},
            },
          },
        };

        if (newDHWSettings.state) {
          result.installerSettings.commissioning.additionalDhwHeatingSource.state =
            newDHWSettings.state;
        }

        if (newDHWSettings.inputPower) {
          result.installerSettings.commissioning.additionalDhwHeatingSource.inputPower =
            newDHWSettings.inputPower;
        }
      }

      if (
        newDHWSettings.deltaDeviationTemp ||
        newDHWSettings.additionalSourcePriorityForDhw ||
        newDHWSettings.active
      ) {
        result = {
          ...result,
          installerSettings: {
            ...result?.installerSettings,
            commissioning: {
              ...result?.installerSettings?.commissioning,
              dhw: {},
            },
          },
        };

        if (newDHWSettings.deltaDeviationTemp) {
          result.installerSettings.commissioning.dhw.deltaDeviationTemp =
            newDHWSettings.deltaDeviationTemp;
        }

        if (newDHWSettings.additionalSourcePriorityForDhw) {
          result.installerSettings.commissioning.dhw.additionalSourcePriorityForDhw =
            newDHWSettings.additionalSourcePriorityForDhw;
        }

        if (newDHWSettings.active) {
          result.installerSettings.commissioning.dhw.active =
            newDHWSettings.active;
        }
      }
    }

    return result;
  };

  const onConfirmSettigsChanges = () => {
    const updatedSettings = { ...initialSettings };
    // if (initialSettings?.dhw?.deltaDeviationTemp) {
    if (
      initialSettings?.installerSettings?.commissioning?.dhw?.deltaDeviationTemp
    ) {
      // updatedSettings.dhw = {
      //   ...(updatedSettings.dhw as any),
      //   deltaDeviationTemp: installerDHWSettingsData!.deltaDeviationTemp!,
      // };
      updatedSettings.installerSettings = {
        ...(updatedSettings.installerSettings as any),
        commissioning: {
          ...(updatedSettings.installerSettings?.commissioning as any),
          dhw: {
            ...(updatedSettings.installerSettings?.commissioning.dhw as any),
            deltaDeviationTemp: installerDHWSettingsData!.deltaDeviationTemp!,
          },
        },
      };
    }

    if (
      initialSettings?.installerSettings?.commissioning?.dhw
        ?.additionalSourcePriorityForDhw
    ) {
      updatedSettings.installerSettings = {
        ...(updatedSettings.installerSettings as any),
        commissioning: {
          ...(updatedSettings.installerSettings?.commissioning as any),
          dhw: {
            ...(updatedSettings.installerSettings?.commissioning.dhw as any),
            additionalSourcePriorityForDhw:
              installerDHWSettingsData!.additionalSourcePriorityForDhw!,
          },
        },
      };
    }

    if (initialSettings?.installerSettings?.commissioning?.dhw?.active) {
      updatedSettings.installerSettings = {
        ...(updatedSettings.installerSettings as any),
        commissioning: {
          ...(updatedSettings.installerSettings?.commissioning as any),
          dhw: {
            ...(updatedSettings.installerSettings?.commissioning.dhw as any),
            active: installerDHWSettingsData!.active!,
          },
        },
      };
    }

    if (initialSettings?.dhw?.preferredTemp) {
      updatedSettings.dhw = {
        ...(updatedSettings.dhw as any),
        preferredTemp: installerDHWSettingsData!.preferredTemp!,
      };
    }
    if (
      initialSettings?.installerSettings?.commissioning
        ?.additionalDhwHeatingSource?.inputPower
    ) {
      updatedSettings.installerSettings = {
        ...(updatedSettings.installerSettings as any),
        commissioning: {
          ...(updatedSettings.installerSettings?.commissioning as any),
          additionalDhwHeatingSource: {
            ...(updatedSettings.installerSettings?.commissioning
              .additionalDhwHeatingSource as any),
            inputPower: installerDHWSettingsData?.inputPower!,
          },
        },
      };
    }
    if (
      initialSettings?.installerSettings?.commissioning
        ?.additionalDhwHeatingSource?.state
    ) {
      updatedSettings.installerSettings = {
        ...(updatedSettings.installerSettings as any),
        commissioning: {
          ...(updatedSettings.installerSettings?.commissioning as any),
          additionalDhwHeatingSource: {
            ...(updatedSettings.installerSettings?.commissioning
              .additionalDhwHeatingSource as any),
            state: installerDHWSettingsData?.state!,
          },
        },
      };
    }

    setIsConfirmChangesModalOpen(false);
    const requestBody = createRequest();

    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id!, isRealData: params.get("dummyData") === "false" },
            (draftSettings) => {
              Object.assign(draftSettings, updatedSettings);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPoolingIntervalEnabled(true);
      });
  };

  if (!settings || isEmpty(installerDHWSettingsData)) {
    return <NoParametersBlock />;
  }

  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
    >
      <MainTitle
        title={t("pages.installationSettings.installerSettings.dhw.mainTitle")}
      />
      <Box sx={{ mt: theme.spacing.spacingXl }} />

      {installerDHWSettingsData?.active && (
        <SettingsSwitcher
          disabled={isFieldsDisabled}
          label={`${t(
            "pages.installationSettings.installerSettings.dhw.fieldsLabels.dhwTank"
          )}`}
          state={
            installerDHWSettingsData?.active ===
            ActiveOrInactiveForDHWEnum.ACTIVE
              ? true
              : false
          }
          changeState={(val) => {
            updateInstallerDHWSettings(
              "active",
              val
                ? ActiveOrInactiveForDHWEnum.ACTIVE
                : ActiveOrInactiveForDHWEnum.INACTIVE
            );
          }}
          statusOn={`${t("actions.activate")}`}
          statusOff={`${t("actions.deactivate")}`}
        />
      )}
      {installerDHWSettingsData?.active ===
        ActiveOrInactiveForDHWEnum.ACTIVE && (
        <>
            <NumberInput
              initialValue={installerDHWSettingsData?.preferredTemp?.value}
              changeValue={(val) => {
                updateInstallerDHWSettings("preferredTemp", {
                  ...installerDHWSettingsData?.preferredTemp,
                  value: val,
                });
              }}
              decimalPlaces={1}
              step={0.5}
              hint={`${t(
                "pages.installationSettings.dhw.fieldsHints.DHWTemperatureSetPoint",
                {
                  from: installerDHWSettingsData?.preferredTemp.min,
                  to: installerDHWSettingsData?.preferredTemp.max,
                }
              )}`}
              label={`${t(
                "pages.installationSettings.dhw.fieldsLabels.DHWTemperatureSetPoint"
              )}`}
              min={installerDHWSettingsData?.preferredTemp.min}
              max={installerDHWSettingsData?.preferredTemp.max}
              disabled={isFieldsDisabled}
            />
            <NumberInput
              initialValue={installerDHWSettingsData?.deltaDeviationTemp.value}
              changeValue={(val) => {
                updateInstallerDHWSettings("deltaDeviationTemp", {
                  ...installerDHWSettingsData?.deltaDeviationTemp,
                  value: val,
                });
              }}
              decimalPlaces={1}
              step={0.5}
              label={`${t(
                "pages.installationSettings.installerSettings.dhw.fieldsLabels.dhwRestartDeltaT"
              )}`}
              min={installerDHWSettingsData?.deltaDeviationTemp.min}
              max={installerDHWSettingsData?.deltaDeviationTemp.max}
              disabled={isFieldsDisabled}
            />
          {(settingsParameters?.installerSettings?.commissioning
            ?.additionalDhwHeatingSource?.state ||
            settingsParameters?.installerSettings?.commissioning
              ?.additionalDhwHeatingSource?.inputPower.value) && (
            <>
              <Divider
                sx={{
                  width: "100%",
                  borderColor: theme.colors.colorsBorderBorderSecondary,
                }}
              />

              <SecondaryTitle
                title={`${t(
                  "pages.installationSettings.installerSettings.dhw.secondaryTitle"
                )}`}
                variant="md"
              />

              {settingsParameters?.installerSettings?.commissioning
                ?.additionalDhwHeatingSource?.state && (
                <SettingsSwitcher
                  disabled={isFieldsDisabled}
                  label={`${t(
                    "pages.installationSettings.installerSettings.dhw.fieldsLabels.additionalDHWTankHeatingSource"
                  )}`}
                  state={
                    installerDHWSettingsData?.state ===
                    ActiveOrInactiveForDHWEnum.ACTIVE
                      ? true
                      : false
                  }
                  changeState={(val) => {
                    updateInstallerDHWSettings(
                      "state",
                      val
                        ? ActiveOrInactiveForDHWEnum.ACTIVE
                        : ActiveOrInactiveForDHWEnum.INACTIVE
                    );
                  }}
                  statusOn={`${t("actions.activate")}`}
                  statusOff={`${t("actions.deactivate")}`}
                />
              )}
              {installerDHWSettingsData?.state ===
                ActiveOrInactiveForDHWEnum.ACTIVE && (
                <>
                    <NumberInput
                      initialValue={installerDHWSettingsData?.inputPower?.value}
                      changeValue={(val) => {
                        updateInstallerDHWSettings("inputPower", {
                          ...installerDHWSettingsData?.inputPower,
                          value: val,
                        });
                      }}
                      decimalPlaces={1}
                      unit={installerDHWSettingsData?.inputPower?.unit}
                      step={0.5}
                      hint={`${t(
                        "pages.installationSettings.installerSettings.dhw.fieldsHints.inputPower"
                      )}`}
                      label={`${t(
                        "pages.installationSettings.installerSettings.dhw.fieldsLabels.inputPower"
                      )}`}
                      min={0}
                      max={10000}
                      inputWidth="105px"
                      disabled={isFieldsDisabled}
                    />
                  {settingsParameters?.installerSettings?.commissioning?.dhw
                    ?.additionalSourcePriorityForDhw && (
                    <SettingsSwitcher
                      label={`${t(
                        "pages.installationSettings.installerSettings.dhw.fieldsLabels.highPriorityForDHW"
                      )}`}
                      hint={`${t(
                        `pages.installationSettings.installerSettings.dhw.fieldsHints.highPriorityForDHWNotPrioritized`
                      )}`}
                      statusOff={`${t("statuses.makeItPriority")}`}
                      statusOn={`${t("statuses.makeItPriority")}`}
                      state={
                        installerDHWSettingsData?.additionalSourcePriorityForDhw ===
                        AdditionalSourcePriorityForDhwEnum.DHW_TANK
                          ? true
                          : false
                      }
                      disabled={
                        settingsParameters.installerSettings.commissioning
                          ?.additionalInlineHeatingSource?.state ===
                          ActiveOrInactiveEnum.INACTIVE || isFieldsDisabled
                      }
                      changeState={(val) => {
                        updateInstallerDHWSettings(
                          "additionalSourcePriorityForDhw",
                          val
                            ? AdditionalSourcePriorityForDhwEnum.DHW_TANK
                            : AdditionalSourcePriorityForDhwEnum.INLINE
                        );
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      <UnsavedChangesModal
        tabName={t("pages.installationSettings.tabsNames.installerDHW")}
        condition={isSomethingChanged}
      />
      {isConfirmChangesModalOpen && (
        <ConfirmChangesModal
          isOpen={isConfirmChangesModalOpen}
          onCancel={() => setIsConfirmChangesModalOpen(false)}
          onOk={() => onConfirmSettigsChanges()}
          page={`${t("pages.installationSettings.tabsNames.installerDHW")}`}
          block={`${t(
            "pages.installationSettings.tabsNames.installerSettings"
          )}`}
          showHeatPumpNote={
            differencesToShow.state ||
            differencesToShow.active ||
            differencesToShow.additionalSourcePriorityForDhw
          }
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        >
          <DifferencesToShowInModal differencesToShow={differencesToShow} />
        </ConfirmChangesModal>
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onOkPress={() => setIsNotificationModalOpen(false)}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        />
      )}
    </TabWrapper>
  );
}
