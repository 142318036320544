import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import useTheme, { ESThemeType } from "../../../theme/hooks/useTheme";
import { useLocation, useNavigate, useParams } from "react-router";
import { ArrowLeft, Check, RefreshCCW01, SearchLG } from "untitledui-js-base";
import { IFilteredInstallation } from "../fleetManagementPage";
import { useTranslation } from "react-i18next";
import {
  parametersApi,
  useGetParametersListQuery,
  useSetParameterValuesMutation,
} from "../../../store/services/parameters";
import { Button, ButtonType, IconPosition } from "../../../components/Button/Button";
import EditParameter from "./EditParameter";
import { useAppDispatch, useTypedSelector } from "../../../store";
import {
  addTrendParameter,
  deleteAllSettingsParameters,
  deleteSettingsParameter,
  selectSettingsParameters,
  selectTrendParameters,
  setForEditParameter,
  updateSettingsParameter,
} from "../../../store/reducers/parametersSlice";
import TrendParameterItem from "./TrendParameterItem";
import SettingsParameterItem from "./SettingsParameterItem";
import { useSnackbar } from "../../../components/Snackbar/SnackbarContext";

export default function AdjustParameters() {
  const theme = useTheme();
  const { colors, spacing, typography } = theme;
  const styles = getStyles(theme);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const showSnackbar = useSnackbar();
  const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.adjustParameters" });
  const { connectivityId = "" } = useParams<string>();
  const { state }: { state?: { installation?: IFilteredInstallation } } = useLocation();

  const storedTrendParameters = useTypedSelector((state) =>
    selectTrendParameters(state, connectivityId),
  );

  const storedSettingsParameters = useTypedSelector((state) =>
    selectSettingsParameters(state, connectivityId),
  );

  const [setParameterValues] = useSetParameterValuesMutation();
  const { data: parametersList } = useGetParametersListQuery(
    { connectivityId: connectivityId! },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true, refetchOnReconnect: true },
  );

  const [selectedSearchValue, selectSearchValue] = useState<any>();
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [isAllParametersSelected, setAllParametersSelected] = useState<boolean>(false);
  const [isAnyParameterSelected, setIsAnyParameterSelected] = useState<boolean>(false);

  useEffect(() => {
    if (storedSettingsParameters) {
      const selectedParameters = storedSettingsParameters?.filter((p) => p.isSelected);
      const isAllSelected =
        selectedParameters.length === storedSettingsParameters.length &&
        storedSettingsParameters.length > 0;
      const isAnySelected = selectedParameters.length > 0;
      setAllParametersSelected(isAllSelected);
      setIsAnyParameterSelected(isAnySelected);
    }
  }, [storedSettingsParameters]);

  const onSearchChange = (_event: any, newInputValue: string) => {
    setSearchInputValue(newInputValue.trim());
  };

  const addToEditOrTrend = () => {
    const parameterData = parametersList?.find((p) => p.name === selectedSearchValue);
    if (parameterData?.category === "TREND") {
      dispatch(addTrendParameter({ id: connectivityId, parameter: { name: selectedSearchValue } }));
    } else {
      dispatch(
        setForEditParameter({ id: connectivityId, parameter: { name: selectedSearchValue } }),
      );
    }
  };

  const refetchTrendParameters = () => {
    const tagsToInvalidate = storedTrendParameters?.map((p) => ({
      type: "ParameterDetails" as const,
      id: connectivityId + p.name,
    }));
    if (tagsToInvalidate && tagsToInvalidate.length > 0) {
      dispatch(parametersApi.util.invalidateTags(tagsToInvalidate));
    }
    storedTrendParameters?.forEach((p) => {
      dispatch(
        parametersApi.endpoints.getParameterDetails.initiate(
          {
            connectivityId,
            parameterName: p.name,
          },
          { forceRefetch: true },
        ),
      );
    });
  };

  const applySettingParameters = () => {
    const selectedSettingsParameters: { [key: string]: number } = {};
    const selectedStoredSettingsParameter = storedSettingsParameters?.filter(
      (p) => p.isSelected && p.newValue != null,
    );

    selectedStoredSettingsParameter?.forEach(
      (p) => (selectedSettingsParameters[p.name] = p.newValue!),
    );

    setParameterValues({ connectivityId, parameters: selectedSettingsParameters })
      .unwrap()
      .then(() => {
        selectedStoredSettingsParameter?.forEach((p) => {
          dispatch(
            parametersApi.util.updateQueryData(
              "getParameterDetails",
              { connectivityId, parameterName: p.name },
              (draftDetails) => {
                draftDetails.value = p.newValue!;
              },
            ),
          );
          dispatch(
            updateSettingsParameter({
              id: connectivityId,
              parameter: { ...p, newValue: undefined },
            }),
          );
        });
        showSnackbar("Your parameter set has been successfully submitted. ", {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 3000,
          severity: "success",
        });
      }).catch(() => {
        showSnackbar("An error occurred while submitting your parameter set. ", {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 3000,
          severity: "error",
        });
      });
  };

  const deleteFromSettingsParameters = () => {
    if (isAllParametersSelected) {
      dispatch(deleteAllSettingsParameters(connectivityId));
    } else {
      const selectedSettingsParameters = storedSettingsParameters?.filter((p) => p.isSelected);
      selectedSettingsParameters?.forEach((p) => {
        dispatch(deleteSettingsParameter({ id: connectivityId, parameter: p }));
      });
    }
  };

  const selectSettingsParameter = (checked: boolean, identifier: string) => {
    const currentParameter = storedSettingsParameters?.find((p) => p.name === identifier);
    if (currentParameter) {
      dispatch(
        updateSettingsParameter({
          id: connectivityId,
          parameter: { ...currentParameter, isSelected: checked },
        }),
      );
    }
  };

  const selectAllSettingParameters = (checked: boolean) => {
    storedSettingsParameters?.forEach((p) => {
      dispatch(
        updateSettingsParameter({
          id: connectivityId,
          parameter: { ...p, isSelected: checked },
        }),
      );
    });
  };

  return (
    <Box sx={styles.container}>
      <Box sx={{ mb: spacing.spacing6xl, display: "flex" }}>
        <Box
          component={"button"}
          sx={styles.headerBackBtn}
          onClick={() => navigate("/admin/commercial-installations")}
        >
          <ArrowLeft
            style={{
              color: colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
            }}
            size="18"
            strokeWidth={2}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: spacing.spacingLg,
          }}
        >
          <Box
            sx={{
              mb: spacing.spacingXs,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                ...typography[".display-xs-semibold"],
                color: colors.colorsTextTextPrimary,
                mr: spacing.spacingLg,
              }}
            >
              {state?.installation?.name || connectivityId}
            </Typography>
          </Box>
          <Typography
            sx={{
              ...typography[".text-md-regular"],
              color: colors.colorsTextTextTertiary,
            }}
          >
            {state?.installation?.location || ""}
          </Typography>
        </Box>
      </Box>
      <Grid container justifyContent={"flex-start"} sx={{ pl: spacing.spacingXl }}>
        <Grid item xs={12}>
          <Box sx={styles.underlinedHeaderStyles}>
            <Typography
              sx={{
                ...typography[".text-xl-semibold"],
                color: colors.colorsTextTextPrimary,
              }}
            >
              {t("title")}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          columnSpacing={{ xs: 0, md: 6 }}
          rowSpacing={{ xs: 6, md: 0 }}
          justifyContent={"flex-start"}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Grid item xs={12}>
              <Box sx={{ mb: spacing.spacing3xl }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                  <Autocomplete
                    disablePortal
                    autoSelect
                    options={parametersList?.map((p) => p.name) ?? []}
                    sx={{ maxWidth: "320px", flex: 1 }}
                    freeSolo
                    componentsProps={{
                      paper: {
                        sx: {
                          padding: 0,
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder={t("searchPlaceholder") || ""}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchLG size="15px" color={colors.colorsForegroundFgQuaternary} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    renderOption={(props, option, state, ownerState) => {
                      const { key, ...optionProps } = props;
                      return (
                        <Box
                          key={key}
                          component="li"
                          {...optionProps}
                          style={{
                            ...optionProps.style,
                            ...styles.optionStyle,
                            background: state.selected
                              ? colors.colorsBackgroundBgActive
                              : optionProps.style?.background,
                          }}
                          sx={{
                            "&:hover": {
                              background: `${colors.colorsBackgroundBgActive} !important`,
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              ...typography[".text-md-medium"],
                              color: colors.colorsTextTextPrimary,
                              lineBreak: "anywhere",
                              maxWidth: state.selected ? "90%" : "100%",
                            }}
                          >
                            {ownerState.getOptionLabel(option)}
                          </Typography>
                          {state.selected && (
                            <Check size="13" color={colors.colorsForegroundFgBrandPrimary} />
                          )}
                        </Box>
                      );
                    }}
                    inputValue={searchInputValue}
                    onInputChange={onSearchChange}
                    onChange={(_event, newValue) => {
                      selectSearchValue(newValue);
                    }}
                  />
                  <Button
                    buttonType={ButtonType.Primary}
                    disabled={!selectedSearchValue}
                    label={t("addBtn") || ""}
                    onClick={addToEditOrTrend}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid container sx={{ maxWidth: "480px" }} rowSpacing={8}>
              <Grid item xs={12}>
                <EditParameter connectivityId={connectivityId} />
              </Grid>
              <Grid item xs={12}>
                {!!storedSettingsParameters?.length && (
                  <Grid container rowGap={spacing.spacingLg}>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          ...typography[".text-md-semibold"],
                          color: colors.colorsTextTextPrimary,
                        }}
                      >
                        {t("settingTitle")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction="column" rowGap={spacing.spacingLg}>
                        <Grid item sx={styles.settingsItemContainerStyles} xs={12}>
                          <FormControlLabel
                            sx={{
                              ...typography[".text-sm-semibold"],
                              color: colors.colorsTextTextPrimary,
                            }}
                            control={
                              <Checkbox
                                sx={styles.checkBoxStyle}
                                size="small"
                                checked={isAllParametersSelected}
                                onChange={(_e, checked) => {
                                  selectAllSettingParameters(checked);
                                }}
                              />
                            }
                            label={t("allParamsLabel")}
                          />
                        </Grid>
                        {storedSettingsParameters?.map((p, index) => (
                          <SettingsParameterItem
                            connectivityId={connectivityId}
                            isSelected={p.isSelected || false}
                            onSelect={selectSettingsParameter}
                            parameter={p}
                            key={`p_setting_${index}`}
                          />
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", gap: "12px" }}>
                      <Button
                        disabled={!isAnyParameterSelected}
                        buttonType={ButtonType.SecondaryColor}
                        label={t("removeSelectedBtn") || ""}
                        onClick={deleteFromSettingsParameters}
                      />
                      <Button
                        disabled={!isAnyParameterSelected}
                        buttonType={ButtonType.Primary}
                        label={t("applySelectedBtn") || ""}
                        onClick={applySettingParameters}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {!!storedTrendParameters?.length && (
              <>
                <Box sx={styles.underlinedHeaderStyles}>
                  <Typography
                    sx={{
                      ...typography[".text-md-semibold"],
                      color: colors.colorsTextTextPrimary,
                      pb: spacing.spacingXs,
                    }}
                  >
                    {t("trendTitle")}
                  </Typography>
                  <Typography
                    sx={{
                      ...typography[".text-sm-regular"],
                      color: colors.colorsTextTextQuaternary,
                      pb: spacing.spacingXs,
                    }}
                  >
                    {t("trendDescription")}
                  </Typography>
                </Box>
                <Box sx={{ pb: spacing.spacingXl }}>
                  <Button
                    buttonType={ButtonType.LinkColor}
                    label={t("refreshTrendBtn") || ""}
                    Icon={() => (
                      <RefreshCCW01
                        size="16px"
                        color={
                          colors.componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFg
                        }
                      />
                    )}
                    iconPosition={IconPosition.Left}
                    onClick={refetchTrendParameters}
                  />
                </Box>
                <Grid container rowGap={spacing.spacingXl}>
                  {storedTrendParameters?.map((p, index) => (
                    <TrendParameterItem
                      connectivityId={connectivityId}
                      parameterName={p.name}
                      key={`p_trend_${index}`}
                    />
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

const getStyles = ({ colors, spacing, typography, radius }: ESThemeType) => {
  return {
    container: {
      display: "flex",
      padding: `${spacing.spacing2xl} ${spacing.spacing2xl}`,
      flexDirection: "column",
      backgroundColor: colors.colorsBackgroundBgPrimary,
      height: "calc(100vh - 64px)",
      overflowY: "auto",
    },
    headerBackBtn: {
      backgroundColor: "initial",
      height: "56px",
      width: "56px",
      border: `1px solid ${colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBorder}`,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: colors.colorsBackgroundBgSecondaryHover,
      },
    },
    labelBaseStyles: {
      ...typography[".text-xs-medium"],
      height: "22px",
      padding: `${spacing.spacingXxs} ${spacing.spacingMd} ${spacing.spacingXxs} ${spacing.spacingSm}`,
      borderRadius: "10px",
      border: `1px solid ${colors.componentColorsUtilityGrayUtilityGray600}`,
      color: colors.componentColorsUtilityGrayUtilityGray600,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    } as SxProps,

    dotStyles: {
      width: "6px",
      height: "6px",
      borderRadius: "50%",
      backgroundColor: colors.componentColorsUtilityBrandUtilityBrand600,
      mr: spacing.spacingSm,
    } as SxProps,

    settingsItemContainerStyles: {
      padding: `${spacing.spacingLg} ${spacing.spacingXl}`,
      border: `solid 1px ${colors.colorsBorderBorderSecondary}`,
      borderRadius: radius.radiusSm,
      "&.selected": {
        borderColor: colors.colorsBorderBorderBrandSolid,
      },
    },

    checkBoxContainerStyle: {
      display: "flex",
      flex: 1,
      mb: spacing.spacing2xl,
      mr: 0,
    } as SxProps,

    checkBoxStyle: {
      "&.Mui-checked": {
        color: colors.colorsBackgroundBgBrandSolid,
      },
    } as SxProps,

    checkBoxLabelContainerStyle: { display: "flex", flexDirection: "row", flex: 1 } as SxProps,

    checkBoxLabelStyle: {
      ...typography[".text-sm-semibold"],
      color: colors.colorsTextTextPrimary,
      flex: 1,
      pr: spacing.spacingXl,
      wordBreak: "break-all",
    } as SxProps,

    settingWrapperStyles: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.spacingLg,
      ml: "26px",
    } as SxProps,

    settingsRowStyles: {
      display: "flex",
      flex: 1,
    } as SxProps,

    settingsRowLabel: {
      display: "flex",
      flex: 1,
      pr: spacing.spacingXl,
    } as SxProps,

    underlinedHeaderStyles: {
      pb: spacing.spacing2xl,
      borderBottom: `solid 1px ${colors.colorsBorderBorderSecondary}`,
      mb: spacing.spacing2xl,
    } as SxProps,

    optionStyle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "0 5px",
      padding: "5px",
      borderRadius: radius.radiusSm,
    } as CSSProperties,
  };
};
