import { Box, MenuItem, Select, SxProps, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { ChevronDown } from "untitledui-js-base";

export interface ISimpleDropdownProps {
  currentValue: any;
  dropdownOptions: Array<{ value: any; label: any }>;
  label?: string;
  labelColor?: string;
  sx?: SxProps;
  onChange: (val: any) => void;
  disabled?: boolean;
}

export default function SimpleDropdown({
  currentValue,
  dropdownOptions,
  label,
  labelColor,
  sx,
  onChange,
  disabled,
}: ISimpleDropdownProps) {
  const theme = useTheme();

  const [isDisabled, setIsDisabled] = useState<boolean>(!!disabled);

  useEffect(() => {
    setIsDisabled(!!disabled);
  }, [disabled]);

  return (
    <Box sx={{ ...sx, mb: theme.spacing.spacingXl }}>
      {label && (
        <Typography
          sx={{
            ...theme.typography[".text-sm-medium"],
            color: labelColor || theme.colors.colorsTextTextSecondary,
            mb: theme.spacing.spacingSm,
          }}
        >
          {label}
        </Typography>
      )}
      <Select
        labelId="number-select-label"
        id="number-select"
        disabled={isDisabled}
        sx={{
          ...theme.typography[".text-md-medium"],
          minWidth: "112px",
          height: "44px",
          backgroundColor: isDisabled
            ? theme.colors.colorsBackgroundBgDisabled
            : "#fff",
          borderRadius: "8px",
          border: `1px solid ${
            isDisabled
              ? theme.colors.colorsBorderBorderDisabled
              : theme.colors.colorsBorderBorderPrimary
          }`,
          color: isDisabled
            ? theme.colors.colorsTextTextDisabled
            : theme.colors.colorsTextTextPrimary,
          outline: "none",
          fieldset: {
            border: "none",
          },
          div: {
            padding: `0 14px`,
          },
        }}
        value={currentValue != null ? (currentValue as string) : ""}
        displayEmpty
        onChange={(e) => {
          if (isDisabled) {
            return;
          }
          onChange(e.target.value);
        }}
        IconComponent={(props) => {
          return isDisabled ? (
            <></>
          ) : (
            <ChevronDown
              {...props}
              style={{ color: theme.colors.colorsForegroundFgQuaternary }}
              size="20"
              strokeWidth={2}
            />
          );
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
                backgroundColor: theme.colors.colorsBackgroundBgSecondaryAlt,
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "4px",
                backgroundColor: "rgba(0,0,0,0.2)",
                minHeight: "24px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.colors.colorsTextTextQuaternary,
              },
            },
            style: {
              maxHeight: 200,
            },
          },
        }}
      >
        <MenuItem value="" disabled sx={{ display: "none" }}>
          Select
        </MenuItem>
        {dropdownOptions.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
