import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import CheckboxWithLabel from "../CheckboxWithLabel/CheckboxWithLabel";
import useTheme from "../../theme/hooks/useTheme";
import { getDropdownWrapperStyles, getSectionTitleStyles } from "./SXStyles";
import { getScrollStyles } from "../../styles/stylesHelpers/scrollStyles";
import { IDropdownContentProps } from "./types";

export default function DropdownContent({
  dropdownRef,
  itemsForDropdown,
  selectedValues,
  dropdownCoords,
  buttonCoords,
  selectAllCheckedCondition,
  onSelectAllChange,
  selectAllLabel,
  onCheckboxChange,
}: IDropdownContentProps) {
  const theme = useTheme();

  const memoizedDropdownContent = useMemo(() => {
    return (
      <Box
        ref={dropdownRef}
        sx={{
          ...getDropdownWrapperStyles({
            theme,
            dropdownCoords,
            buttonCoords,
          }),
        }}
      >
        <Box
          sx={{
            height: `calc(${dropdownCoords?.height}px - (${theme.spacing.spacingSm} * 2))`,
            ...getScrollStyles({ theme }),
          }}
        >
          <CheckboxWithLabel
            checkedCondition={selectAllCheckedCondition}
            onChange={onSelectAllChange}
            label={selectAllLabel}
          />
          {itemsForDropdown?.map((section, sectionIndex) => {
            return (
              <Box key={sectionIndex}>
                {section.sectionTitle && (
                  <Typography
                    sx={{
                      ...getSectionTitleStyles(theme),
                    }}
                  >
                    {section.sectionTitle}
                  </Typography>
                )}
                {section.itemsList.map((item, itemIndex) => (
                  <CheckboxWithLabel
                    key={itemIndex}
                    checkedCondition={selectedValues.includes(item.value)}
                    onChange={() => onCheckboxChange(item)}
                    label={item.label}
                  />
                ))}
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }, [
    dropdownRef,
    dropdownCoords,
    buttonCoords,
    itemsForDropdown,
    selectedValues,
  ]);
  return memoizedDropdownContent;
}
