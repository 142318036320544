export enum InstallationValueUnit {
  CELSIUS = "CELSIUS",
  PERCENT = "PERCENT",
  LITRE_PER_MINUTE = "LITRE_PER_MINUTE",
  HERTZ = "HERTZ",
  BAR = "BAR",
  STEP = "STEP",
  REVOLUTION_PER_MINUTE = "REVOLUTION_PER_MINUTE",
  KILOWATT = "KILOWATT",
  COUNT = "COUNT",
  AMPERE = "AMPERE",
  VOLT = "VOLT",
  MINUTES = "MINUTES",
  HOURS = "HOURS",
  DAYS = "DAYS",
}

export enum Season {
  HEATING = "HEATING",
  COOLING = "COOLING",
  NEUTRAL = "NEUTRAL",
}

export enum OperationStatus {
  HEATING = "HEATING",
  COOLING = "COOLING",
  DHW = "DHW",
  ANTI_LEGIONELLA = "ANTI_LEGIONELLA",
  IDLE = "IDLE",
}

export enum OnOrOffEnum {
  ON = "ON",
  OFF = "OFF",
}

export enum ActiveOrInactiveEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ActiveOrInactiveForDHWEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum SgReadyStatus {
  NORMAL = "NORMAL",
  ENCOURAGED = "ENCOURAGED",
  ORDERED = "ORDERED",
  BLOCKED = "BLOCKED",
}

export enum ElectricGridProtectionStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  REQUESTED = "REQUESTED",
}

export type ZoneOperationsKey =
  | "zone1Operations"
  | "zone2Operations"
  | "zone3Operations"
  | "zone4Operations";

export enum InstallationType {
  LCS_V3 = "LCS_V3",
  DOMESTIC_V3 = "DOMESTIC_V3",
}

export enum OnlineStatus {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export enum IOrganizationType {
  DISTRIBUTOR = "DISTRIBUTOR",
  INSTALLER = "INSTALLER",
  COMMERCIAL_CLIENT = "COMMERCIAL_CLIENT",
  PRIVATE_CLIENT = "PRIVATE_CLIENT",
}

export enum AlertType {
  WARNING = "WARNING",
  ERROR = "ERROR",
  INFO = "INFO",
}

export enum AboutSectionPartsTypes {
  OUTDOOR_UNIT = "OUTDOOR_UNIT",
  INDOOR_UNIT = "INDOOR_UNIT",
  CCV_CONTROL = "CCV_CONTROL",
  DISPLAY_CONTROL = "DISPLAY_CONTROL",
}

export interface IInstallationBaseValueWithMeasurement {
  value: number;
  unit: InstallationValueUnit;
  min: number;
  max: number;
}

export interface IInstallationBaseValue {
  value: number;
  unit: InstallationValueUnit;
}

export interface IInstallationDetailCommissioningInfo {
  commissionerUuid: string;
  commissionedDate: string;
}

export interface IInstallationDetailSharingInfoOrganizationDetails {
  uuid: string;
  name: string;
  type: IOrganizationType;
  country: string;
  address: string;
  phone: string;
}

export interface IInstallationDetailSharingInfo {
  parentOrganization: IInstallationDetailSharingInfoOrganizationDetails;
  myOrganization: IInstallationDetailSharingInfoOrganizationDetails;
  childrenOrganizations: Array<IInstallationDetailSharingInfoOrganizationDetails>;
  clientOrgUuid: string;
}
export interface IInstallationDetails {
  uuid: string;
  name: string;
  type: InstallationType;
  onlineStatus: OnlineStatus;
  location: string;
  connectivityId: string;
  lastCommunicationDate: string;
  commissioningInfo: IInstallationDetailCommissioningInfo;
  sharingInfo: IInstallationDetailSharingInfo;
}

export interface IPaginationData {
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
}

export interface OrganizationAlertItem {
  type: AlertType;
  code: string;
  alertStartTimestamp: string;
  alertAcknowledgedTimestamp: string;
  alertEndTimestamp: string;
  active: boolean;
  acknowledgeable: boolean;
}

export interface InstallationHistoryAlertsResponse {
  pagination: IPaginationData;
  result: Array<OrganizationAlertItem>;
}

export interface InstallationHistoryAlertsRequestParams {
  installationUuid: string;
  pageNum: number;
  pageSize: number;
  alertCode: Array<string>;
  startTimestamp: string;
  endTimestamp: string;
  activeOnly: boolean;
  type: Array<AlertType>;
}

export interface InstallationHistoryAlertCodeItem {
  code: string;
  occurredTimes: number;
}

export interface InstallationHistoryAlertsCodesResponse {
  alerts: Array<InstallationHistoryAlertCodeItem>;
}

export interface InstallationHistoryAlertsCodesRequestParams {
  installationUuid: string;
  startTimestamp?: string;
  endTimestamp?: string;
}

export interface ShareInstallationRequestParams {
  installationUuid: string;
  parentOrgUuid?: string;
  childOrgUuid: string;
  isSuperAdmin: boolean;
}

export interface ShareInstallationRequestSuperAdminParams {
  installationUuid: string;
  orgUuid: string;
  installationName: string;
  isSuperAdmin: boolean;
}
