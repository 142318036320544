import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import { Plus } from "untitledui-js-base";
import useTheme from "../../theme/hooks/useTheme";

export interface IEmptyInstallationPageProps {
  icon: any;
  title: string;
  description: string;
  withButton?: boolean;
  onButtonClick?: () => void;
  buttonText?: string;
}

export default function EmptyInstallationPage({
  icon,
  title,
  description,
  withButton,
  onButtonClick,
  buttonText,
}: IEmptyInstallationPageProps) {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        width: "100%",
        padding: `${theme.spacing.spacing5xl} ${theme.spacing.spacing4xl}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "48px",
          height: "48px",
          borderRadius: "50%",
          padding: theme.spacing.spacingLg,
          mb: theme.spacing.spacingXl,
          backgroundColor: theme.colors.componentColorsUtilityGrayUtilityGray50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon}
      </Box>
      <Typography
        sx={{
          ...theme.typography[".text-md-semibold"],
          mb: theme.spacing.spacingXs,
          color: theme.colors.colorsTextTextPrimary,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          ...theme.typography[".text-sm-regular"],
          color: theme.colors.colorsTextTextTertiary,
          mb: theme.spacing.spacing3xl,
          maxWidth: "360px",
          textAlign: "center",
        }}
      >
        {description}
      </Typography>
      {withButton && (
        <Button
          variant="contained"
          color="secondary"
          sx={{
            textTransform: "initial",
            backgroundColor: "#779A19",
          }}
          type="submit"
          startIcon={<Plus size="16" color="#FFF" strokeWidth={2} />}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      )}
    </Paper>
  );
}
