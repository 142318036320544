import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTheme from "../../../../../../theme/hooks/useTheme";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import { Box } from "@mui/material";
import InstallerSettingsSubMenu, {
  ISubNavItem,
} from "./SubMenu/InstallerSettingsSubMenu";
import PrivateRoute from "../../../../../../components/PrivateRoute/PrivateRoute";
import InstallerZones from "./Tabs/Zones/InstallerZones";
import InstallerDHW from "./Tabs/DHW/InstallerDHW";
import AdditionalInlineHeatingSource from "./Tabs/AdditionallnlineHeatingSource/AdditionalInlineHeatingSource";
import BufferTank from "./Tabs/BufferTank/BufferTank";
import ShiftingPriorities from "./Tabs/ShiftingPriorities/ShiftingPriorities";
import AdditionalHeatingSourcePrioritiesAndThresholds from "./Tabs/AdditionalHeatingSourcePrioritiesAndThresholds/AdditionalHeatingSourcePrioritiesAndThresholds";
import Others from "./Tabs/OthersTab/Others";

export default function InstallerSettings({
  isInstallationOffline,
  baseUrl,
}: {
  isInstallationOffline?: boolean;
  baseUrl: string;
}) {
  const { t: installerT } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.installerSettings",
  });
  const { t: subMenuT } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.installerSettings.subMenuItems",
  });
  const theme = useTheme();

  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const [subMenuItems, setSubMenuItems] = useState<Array<ISubNavItem>>([]);
  const [isFieldsDisabled, setIsFieldsDisabled] = useState<boolean>(
    !!isInstallationOffline
  );

  useEffect(() => {
    setIsFieldsDisabled(!!isInstallationOffline);
  }, [isInstallationOffline]);

  useEffect(() => {
    if (
      location.pathname.endsWith("/installation-settings-installer-settings")
    ) {
      navigate(`${baseUrl}/zones${location.search}`);
    }

    if (id) {
      setSubMenuItems([
        {
          label: subMenuT("zones"),
          href: `${baseUrl}/zones${location.search}`,
        },
        {
          label: subMenuT("dhw"),
          href: `${baseUrl}/dhw${location.search}`,
        },
        {
          label: subMenuT("bufferTank"),
          href: `${baseUrl}/buffer-tank${location.search}`,
        },
        {
          label: subMenuT("additionalInlineHeatingSource"),
          href: `${baseUrl}/additional-inline-heating-source${location.search}`,
        },
        {
          label: subMenuT("additionalHeatingSourceThresholds"),
          href: `${baseUrl}/additional-heating-source-thresholds${location.search}`,
        },
        {
          label: subMenuT("shiftingPriorities"),
          href: `${baseUrl}/shifting-priorities${location.search}`,
        },
        // {
        //   label: subMenuT("sgReady"),
        //   href: `${baseUrl}/sg-ready${location.search}`,
        // },
        {
          label: subMenuT("other"),
          href: `${baseUrl}/other${location.search}`,
        },
      ]);
    }
  }, [id]);

  return (
    <Box sx={{ height: "100%", display: "flex" }}>
      <InstallerSettingsSubMenu menuItems={subMenuItems} />
      <Box sx={{ width: "100%" }}>
        <Routes>
          <Route
            path="/zones/*"
            element={
              <PrivateRoute
                element={
                  <InstallerZones isInstallationOffline={isFieldsDisabled} baseUrl={`${baseUrl}/zones`} />
                }
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          />
          <Route
            path="/dhw"
            element={
              <PrivateRoute
                element={
                  <InstallerDHW isInstallationOffline={isFieldsDisabled} />
                }
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          />
          <Route
            path="/additional-inline-heating-source"
            element={
              <PrivateRoute
                element={
                  <AdditionalInlineHeatingSource
                    isInstallationOffline={isFieldsDisabled}
                  />
                }
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          />
          <Route
            path="/buffer-tank"
            element={
              <PrivateRoute
                element={
                  <BufferTank isInstallationOffline={isFieldsDisabled} />
                }
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          />
          <Route
            path="/shifting-priorities"
            element={
              <PrivateRoute
                element={
                  <ShiftingPriorities
                    isInstallationOffline={isFieldsDisabled}
                  />
                }
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          />
          <Route
            path="/additional-heating-source-thresholds"
            element={
              <PrivateRoute
                element={
                  <AdditionalHeatingSourcePrioritiesAndThresholds
                    isInstallationOffline={isFieldsDisabled}
                  />
                }
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          />
          <Route
            path="/other"
            element={
              <PrivateRoute
                element={<Others isInstallationOffline={isFieldsDisabled} />}
                rolesToCheck={["INSTALLATION_READ"]}
              />
            }
          />
        </Routes>
      </Box>
    </Box>
  );
}
