import { Box, Typography } from "@mui/material";
import React, { Ref, useMemo } from "react";
import useTheme from "../../theme/hooks/useTheme";
import {
  CheckSquare,
  ChevronDown,
  ChevronUp,
  Square,
  XClose,
} from "untitledui-js-base";
import { IDropdownButtonOpenerProps } from "./types";

export default function DropdownButtonOpener({
  buttonRef,
  selectedValuesForDropdown,
  buttonLabel,
  isDropdownOpen,
  handleButtonClick,
  handleClearListClick,
}: IDropdownButtonOpenerProps) {
  const theme = useTheme();
  const { colors, spacing, radius, typography } = theme;
  const memoizedDropdownButtonOpener = useMemo(() => {
    return (
      <Box
        id="dd_button"
        ref={buttonRef}
        component={"button"}
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          backgroundColor:
            colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
          padding: `0 ${spacing.spacingLg}`,
          cursor: "pointer",
          minWidth: "155px",
          height: "40px",
          border: `1px solid ${
            selectedValuesForDropdown.length
              ? colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorder
              : colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBorder
          }`,
          borderRadius: radius.radiusMd,
        }}
        onClick={handleButtonClick}
      >
        {selectedValuesForDropdown.length ? (
          <CheckSquare
            size="20"
            style={{
              color:
                colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg,
              marginRight: spacing.spacingMd,
              pointerEvents: "none",
            }}
            strokeWidth={2}
          />
        ) : (
          <Square
            size="20"
            style={{
              color:
                colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
              marginRight: spacing.spacingMd,
              pointerEvents: "none",
            }}
            strokeWidth={2}
          />
        )}

        <Typography
          sx={{
            ...typography[".text-sm-semibold"],
            color: selectedValuesForDropdown.length
              ? colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg
              : colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
            pointerEvents: "none",
          }}
        >
          {buttonLabel}
        </Typography>
        <Box sx={{ display: "flex", ml: "auto" }}>
          {isDropdownOpen ? (
            <ChevronUp
              size="20"
              style={{
                color: selectedValuesForDropdown.length
                  ? colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg
                  : colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
                marginLeft: spacing.spacingMd,
              }}
              strokeWidth={2}
            />
          ) : selectedValuesForDropdown.length ? (
            <XClose
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClearListClick();
              }}
              size="20"
              style={{
                color:
                  colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg,
                marginLeft: spacing.spacingMd,
              }}
              strokeWidth={2}
            />
          ) : (
            <ChevronDown
              size="20"
              style={{
                color: selectedValuesForDropdown.length
                  ? colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg
                  : colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
                marginLeft: spacing.spacingMd,
              }}
              strokeWidth={2}
            />
          )}
        </Box>
      </Box>
    );
  }, [isDropdownOpen, selectedValuesForDropdown]);
  return memoizedDropdownButtonOpener;
}
