import React from "react";
import useTheme, { ESThemeType } from "../../../theme/hooks/useTheme";
import { useAppDispatch } from "../../../store";
import {
  deleteTrendParameter,
} from "../../../store/reducers/parametersSlice";
import { useGetParameterDetailsQuery } from "../../../store/services/parameters";
import { Box, Grid, SxProps, Typography } from "@mui/material";
import { Button, ButtonType } from "../../../components/Button/Button";
import { Trash04 } from "untitledui-js-base";
import { useTranslation } from "react-i18next";

type TrendParameterItemProps = {
  connectivityId: string;
  parameterName: string;
};

export default function TrendParameterItem({
  connectivityId,
  parameterName,
}: TrendParameterItemProps) {
  const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.adjustParameters" });
  const theme = useTheme();
  const { colors, spacing, typography } = theme;
  const styles = getStyles(theme);
  const dispatch = useAppDispatch();

  const { currentData } = useGetParameterDetailsQuery(
    { connectivityId, parameterName: parameterName },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      pollingInterval: 20000,
    },
  );

  const deleteParameter = () => {
    dispatch(deleteTrendParameter({ id: connectivityId, parameter: { name: parameterName } }));
  };
  return (
    <Grid item container rowGap={spacing.spacingXl}>
      <Grid item xs={12}>
        <Box sx={styles.settingsRowStyles}>
          <Box sx={styles.settingsRowLabel}>
            <Typography
              sx={{
                ...typography[".text-md-semibold"],
                color: colors.colorsTextTextPrimary,
                display: "flex",
                wordBreak: "break-all",
                flex: 1,
              }}
            >
              {currentData?.name || parameterName}
            </Typography>
          </Box>
          <Box>
            <Button
              buttonType={ButtonType.LinkColor}
              Icon={() => <Trash04 size="15px" color={colors.colorsBorderBorderPrimary} />}
              onClick={deleteParameter}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={styles.settingsRowStyles}>
          <Typography
            sx={{
              ...typography[".text-sm-regular"],
              color: colors.colorsTextTextQuaternary,
              pr: "5px",
            }}
          >
            {t("currentValLabel")}:
          </Typography>
          <Typography
            sx={{
              ...typography[".text-sm-semibold"],
              color: colors.colorsTextTextQuaternary,
            }}
          >
            {currentData?.value ?? "--"}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

const getStyles = ({ spacing }: ESThemeType) => {
  return {
    settingsRowStyles: {
      display: "flex",
      flex: 1,
    } as SxProps,

    settingsRowLabel: {
      display: "flex",
      flex: 1,
      pr: spacing.spacingXl,
    } as SxProps,
  };
};
