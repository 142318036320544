import React from "react";
import useTheme from "../../../../../../../theme/hooks/useTheme";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, List, ListItem, ListItemText } from "@mui/material";

export interface ISubNavItem {
  label: string;
  href: string;
}

export interface IInstallerSettingsSubMenu {
  menuItems: Array<ISubNavItem>;
}

export default function InstallerSettingsSubMenu({
  menuItems,
}: IInstallerSettingsSubMenu) {
  const theme = useTheme();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Box
      sx={{
        maxWidth: "285px",
        height: "100%",
        borderRight: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
      }}
    >
      <List sx={{ padding: `0 ${theme.spacing.spacingMd}` }}>
        {menuItems.map((menuItem, index) => (
          <ListItem
            button
            key={`${index}_menuItem_installer-settings`}
            component={RouterLink}
            to={menuItem.href}
            sx={{
              ...theme.typography[".text-md-semibold"],
              maxHeight: "fit-content",
              padding: `0 ${theme.spacing.spacingMd}`,
              color: theme.colors.colorsTextTextQuaternary,
              borderRadius: "10px",
              minHeight: "40px",
              mb: theme.spacing.spacingXs,

              ".MuiTypography-root": {
                color: currentPath.includes(menuItem.href.split("?")[0])
                  ? theme.colors.colorsTextTextBrandTertiaryAlt
                  : "inherit",
              },
              //   backgroundColor: currentPath.includes(menuItem.href.split("?")[0])
              //     ? theme.colors.colorsBackgroundBgActive
              //     : "inital",
            }}
          >
            <ListItemText
              sx={{
                ...theme.typography[".text-md-semibold"],
                color: theme.colors.colorsTextTextQuaternary,
                fontWeight: "600 !important",
              }}
              primary={menuItem.label}
              primaryTypographyProps={{
                style: {
                  fontWeight: 600,
                },
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
