import { SxProps } from "@mui/material";
import { ESThemeType } from "../../theme/hooks/useTheme";

interface IParameters {
  scrollbarColor?: string;
  scrollbarThumbColor?: string;
  scrollbarThumbHoverColor?: string;
  theme: ESThemeType;
}

type getScrollStylesFunction = ({
  theme,
  scrollbarColor,
  scrollbarThumbColor,
  scrollbarThumbHoverColor,
}: IParameters) => SxProps;
export const getScrollStyles: getScrollStylesFunction = ({
  theme,
  scrollbarColor,
  scrollbarThumbColor,
  scrollbarThumbHoverColor,
}) => {
  return {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      backgroundColor:
        scrollbarColor || theme.colors.colorsBackgroundBgSecondaryAlt,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor:
        scrollbarThumbColor || theme.colors.colorsBorderBorderBrandSolidAlt,
      minHeight: "24px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor:
        scrollbarThumbHoverColor || theme.colors.colorsTextTextQuaternary,
    },
  };
};
