import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  IBaseItem,
  IDropdownScrollableWithCheckboxesAndSectionsProps,
  IItemWithSection,
} from "./types";
import useResize from "../../hooks/useResize";
import DropdownButtonOpener from "./DropdownButtonOpener";
import useClickOutside from "../../hooks/useClickOutside";
import DropdownContent from "./DropdownContent";

export default function DropdownScrollableWithCheckboxesAndSections({
  buttonLabel,
  items,
  selectedValues,
  setSelectedValues,
  selectAllLabel,
}: IDropdownScrollableWithCheckboxesAndSectionsProps) {
  const { width } = useResize();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [buttonCoords, setButtonCoords] = useState<DOMRect>();
  const [dropdownCoords, setDropdownCoords] = useState<DOMRect>();

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const [itemsForDropdown, setItemsForDropdown] =
    useState<Array<IItemWithSection>>();
  const [allItemsCount, setAllItemsCount] = useState<number>();

  const [selectedValuesCurrentState, setSelectedValuesCurrentState] =
    useState<Array<string>>(selectedValues);
  const selectedValuesRef = useRef<Array<string>>(selectedValuesCurrentState);

  useEffect(() => {
    selectedValuesRef.current = selectedValuesCurrentState;
  }, [selectedValuesCurrentState]);

  useEffect(() => {
    setItemsForDropdown(items);
    setAllItemsCount(
      items?.reduce((acc, next) => acc + next.itemsList.length, 0)
    );
  }, [items]);

  useEffect(() => {
    setSelectedValuesCurrentState(selectedValues);
  }, [selectedValues]);

  useLayoutEffect(() => {
    if (buttonRef.current) {
      const coords: DOMRect = buttonRef.current.getBoundingClientRect();
      setButtonCoords(coords);
    }
  }, [width, isDropdownOpen]);

  useLayoutEffect(() => {
    if (dropdownRef.current && isDropdownOpen) {
      const coords: DOMRect = dropdownRef.current.getBoundingClientRect();
      setDropdownCoords(coords);
    }
  }, [isDropdownOpen]);

  useClickOutside(
    dropdownRef,
    () => {
      setSelectedValues(selectedValuesRef.current);
      setIsDropdownOpen(false);
    },
    ["dd_button"]
  );

  const handleSelectAllCheckboxChange = () => {
    if (allItemsCount === selectedValuesCurrentState.length) {
      setSelectedValuesCurrentState([]);
    } else {
      let allValues = itemsForDropdown!
        .reduce((acc, next) => acc.concat(next.itemsList as any), [])
        .map((item) => (item as any).value);
      setSelectedValuesCurrentState(allValues);
    }
  };

  const handleParameterCheckboxChange = (item: IBaseItem) => {
    let updatedSelectedValues: Array<string> = [...selectedValuesCurrentState];
    if (selectedValuesCurrentState.includes(item.value)) {
      updatedSelectedValues = updatedSelectedValues.filter((val) => {
        return val !== item.value;
      });
    } else {
      updatedSelectedValues.push(item.value);
    }
    setSelectedValuesCurrentState(updatedSelectedValues);
  };

  const onDropdownOpenerClick = () => {
    if (isDropdownOpen) {
      setSelectedValues(selectedValuesCurrentState);
    }
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onClearSelectionClick = () => {
    setSelectedValuesCurrentState([]);
    setSelectedValues([]);
  };

  return (
    <>
      <DropdownButtonOpener
        buttonLabel={buttonLabel}
        buttonRef={buttonRef}
        isDropdownOpen={isDropdownOpen}
        selectedValuesForDropdown={selectedValuesCurrentState}
        handleButtonClick={onDropdownOpenerClick}
        handleClearListClick={onClearSelectionClick}
      />
      {isDropdownOpen && (
        <DropdownContent
          dropdownRef={dropdownRef}
          itemsForDropdown={itemsForDropdown}
          selectedValues={selectedValuesCurrentState}
          buttonCoords={buttonCoords}
          dropdownCoords={dropdownCoords}
          selectAllCheckedCondition={
            allItemsCount === selectedValuesCurrentState.length
          }
          onSelectAllChange={handleSelectAllCheckboxChange}
          selectAllLabel={selectAllLabel}
          onCheckboxChange={(item) => handleParameterCheckboxChange(item)}
        />
      )}
    </>
  );
}
