import React, { useEffect, useState } from "react";
import TabWrapper from "../../TabWrapper";
import MainTitle from "../../components/MainTitle/MainTitle";
import { useTranslation } from "react-i18next";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../store";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useGetInstallationStateQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../store/services/installation";
import SimpleDisplayValue from "../../components/SimpleDisplayValue/SimpleDisplayValue";
import { Box, Divider, Typography } from "@mui/material";
import {
  IInstallationDHWSettings,
  IInstallationSettingsByIdResponse,
  ISetDhwSettings,
  ISetInstallationSettings,
} from "../../../../../../store/services/models/installations/installationSettings";
import NumberInput from "../../components/NumberInput/NumberInput";
import SecondaryTitle from "../../components/SecondaryTitle/SecondaryTitle";
import SettingsSwitcher from "../../components/SettingsSwitcher/SettingsSwitcher";
import { ActiveOrInactiveEnum } from "../../../../../../store/services/models/installations/installationsCommon";
import TimeInput from "../../components/TimeInput/TimeInput";
import SimpleDropdown from "../../components/SimpleDropdown/SimpleDropdown";
import UnsavedChangesModal from "../../components/UnsavedChangesModal/UnsavedChangesModal";
import {
  baseFindDifferences,
  cleanValuesForRequest,
  isEmpty,
} from "../../helpers/findDifferences";
import ConfirmChangesModal from "../../components/ConfirmChangesModal/ConfirmChangesModal";
import DifferencesToShowInModal from "./DifferencesToShowInModal";
import { ReactComponent as DHWIcon } from "../../../RealTimeData/SectionIcons/DHWIcon.svg";
import NotificationModal from "../../components/NotificationModal/NotificationModal";
import NoParametersBlock from "../../NoParametersBlock";

export default function DomesticHotWater({
  isInstallationOffline,
}: {
  isInstallationOffline?: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const [isPoolingIntervalEnabled, setIsPoolingIntervalEnabled] =
    useState<boolean>(true);

  const {
    data: installationState,
    isFetching: isFetchingState,
    isLoading: isLoadingState,
    refetch: refetchState,
  } = useGetInstallationStateQuery(
    {
      uuid: id!,
      isRealData: params.get("dummyData") === "false",
    },
    {
      pollingInterval: isPoolingIntervalEnabled ? 60000 : 0,
    }
  );

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery(
    {
      uuid: id!,
      isRealData: params.get("dummyData") === "false",
    },
    {
      pollingInterval: isPoolingIntervalEnabled ? 60000 : 0,
    }
  );

  const [isFieldsDisabled, setIsFieldsDisabled] = useState<boolean>(
    !!isInstallationOffline
  );

  const [setInstallationSettings] = useSetInstallationSettingsMutation();

  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);

  const [DHWSettingsData, setDHWSettingsData] =
    useState<IInstallationDHWSettings>();
  const [initalDHWSettingsData, setInitalDHWSettingsData] =
    useState<IInstallationDHWSettings>();
  const [initialSettings, setInitialSettings] =
    useState<IInstallationSettingsByIdResponse | null>(null);

  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [differencesToShow, setDifferencesToShow] = useState<any>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  const weeksValuesArray = Array.from({ length: 8 }, (_, i) => ({
    value: 7 * (i + 1),
    label: `${i + 1} ${t(
      `pages.installationSettings.dhw.${i === 0 ? "week" : "weeks"}`
    )}`,
  }));

  useEffect(() => {
    setIsFieldsDisabled(!!isInstallationOffline);
  }, [isInstallationOffline]);

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setDHWSettingsData(
        JSON.parse(JSON.stringify(settingsParameters.dhw || {}))
      );
      setInitalDHWSettingsData(
        JSON.parse(JSON.stringify(settingsParameters.dhw || {}))
      );
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (
      JSON.stringify(DHWSettingsData) !== JSON.stringify(initalDHWSettingsData)
    ) {
      setIsSomethingChanged(true);
      setIsPoolingIntervalEnabled(false);
    } else {
      setIsSomethingChanged(false);
      setIsPoolingIntervalEnabled(true);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [DHWSettingsData]);

  const updatePreferredTemp = (value: any) => {
    const updatedDHWSettingsData = { ...DHWSettingsData };
    updatedDHWSettingsData.preferredTemp = value;
    setDHWSettingsData(updatedDHWSettingsData as IInstallationDHWSettings);
  };

  const updateAntiLegionellaSettings = (value: any, field: string) => {
    const updatedDHWSettingsData = { ...DHWSettingsData };
    (updatedDHWSettingsData as any).antiLegionella[field] = value;
    setDHWSettingsData(updatedDHWSettingsData as IInstallationDHWSettings);
  };

  const onDiscardChanges = () => {
    setDHWSettingsData(JSON.parse(JSON.stringify(initalDHWSettingsData)));
    setIsPoolingIntervalEnabled(true);
  };

  const onSaveChanges = () => {
    const differences = baseFindDifferences(
      initalDHWSettingsData!,
      DHWSettingsData!
    );
    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    let newDHWSettings: Partial<ISetDhwSettings> =
      cleanValuesForRequest(differencesToShow);

    return {
      dhw: newDHWSettings,
    };
  };

  const onConfirmSettigsChanges = () => {
    const updatedSettings = { ...initialSettings };
    updatedSettings.dhw = DHWSettingsData;
    setIsConfirmChangesModalOpen(false);
    const requestBody = createRequest();
    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id!, isRealData: params.get("dummyData") === "false" },
            (draftSettings) => {
              Object.assign(draftSettings, updatedSettings);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPoolingIntervalEnabled(true);
      });
  };

  if (
    (!settings && !installationState) ||
    isEmpty(settings?.dhw) ||
    isEmpty(DHWSettingsData)
  ) {
    return <NoParametersBlock />;
  }

  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
    >
      <MainTitle title={t("pages.installationSettings.dhw.mainTitle")} />
      <Box sx={{ mt: theme.spacing.spacingXl }} />

        <SimpleDisplayValue
          label={t(
            "pages.installationSettings.dhw.fieldsLabels.DHWTemperature"
          )}
          value={`${installationState?.dhw.currentTemp.value} °C`}
        />
        <NumberInput
          initialValue={DHWSettingsData?.preferredTemp.value}
          changeValue={(val) =>
            updatePreferredTemp({
              ...DHWSettingsData?.preferredTemp,
              value: val,
            })
          }
          decimalPlaces={1}
          step={0.5}
          hint={`${t(
            "pages.installationSettings.dhw.fieldsHints.DHWTemperatureSetPoint",
            {
              from: DHWSettingsData?.preferredTemp?.min,
              to: DHWSettingsData?.preferredTemp?.max,
            }
          )}`}
          label={`${t(
            "pages.installationSettings.dhw.fieldsLabels.DHWTemperatureSetPoint"
          )}`}
          min={DHWSettingsData?.preferredTemp?.min}
          max={DHWSettingsData?.preferredTemp?.max}
          disabled={isFieldsDisabled}
        />

      {DHWSettingsData?.antiLegionella && (
        <>
          {DHWSettingsData?.antiLegionella?.state && (
            <>
              <Divider
                sx={{
                  width: "100%",
                  borderColor: theme.colors.colorsBorderBorderSecondary,
                }}
              />
              <SecondaryTitle
                title={`${t(
                  "pages.installationSettings.dhw.antiLegionellaTitle"
                )}`}
                variant="md"
              />
              <SettingsSwitcher
                disabled={isFieldsDisabled}
                label={`${t(
                  "pages.installationSettings.dhw.antiLegionellaTitle"
                )}`}
                state={
                  DHWSettingsData?.antiLegionella?.state ===
                  ActiveOrInactiveEnum.ACTIVE
                    ? true
                    : false
                }
                changeState={(val) => {
                  updateAntiLegionellaSettings(
                    val
                      ? ActiveOrInactiveEnum.ACTIVE
                      : ActiveOrInactiveEnum.INACTIVE,
                    "state"
                  );
                }}
              />
            </>
          )}
          {DHWSettingsData?.antiLegionella &&
            DHWSettingsData?.antiLegionella?.state ===
              ActiveOrInactiveEnum.ACTIVE && (
              <>
                  <NumberInput
                    initialValue={DHWSettingsData?.antiLegionella?.temp.value}
                    changeValue={(val) =>
                      updateAntiLegionellaSettings(
                        {
                          ...DHWSettingsData?.antiLegionella?.temp,
                          value: val,
                        },
                        "temp"
                      )
                    }
                    decimalPlaces={1}
                    step={0.5}
                    label={`${t(
                      "pages.installationSettings.dhw.fieldsLabels.antiLegionellaTempSetPoint"
                    )}`}
                    min={DHWSettingsData?.antiLegionella?.temp?.min}
                    max={DHWSettingsData?.antiLegionella?.temp?.max}
                    disabled={isFieldsDisabled}
                  />
                  <NumberInput
                    initialValue={
                      DHWSettingsData?.antiLegionella?.duration?.value
                    }
                    changeValue={(val) =>
                      updateAntiLegionellaSettings(
                        {
                          ...DHWSettingsData?.antiLegionella?.duration,
                          value: val,
                        },
                        "duration"
                      )
                    }
                    decimalPlaces={0}
                    label={`${t(
                      "pages.installationSettings.dhw.fieldsLabels.antiLegionellaDuration"
                    )}`}
                    unit={DHWSettingsData?.antiLegionella?.duration?.unit}
                    min={DHWSettingsData?.antiLegionella?.duration?.min}
                    max={DHWSettingsData?.antiLegionella?.duration?.max}
                    disabled={isFieldsDisabled}
                  />
                  <NumberInput
                    initialValue={
                      DHWSettingsData?.antiLegionella?.timeout?.value
                    }
                    changeValue={(val) =>
                      updateAntiLegionellaSettings(
                        {
                          ...DHWSettingsData?.antiLegionella?.timeout,
                          value: val,
                        },
                        "timeout"
                      )
                    }
                    decimalPlaces={0}
                    label={`${t(
                      "pages.installationSettings.dhw.fieldsLabels.antiLegionellaTimeOut"
                    )}`}
                    unit={DHWSettingsData?.antiLegionella?.timeout?.unit}
                    min={DHWSettingsData?.antiLegionella?.timeout?.min}
                    max={DHWSettingsData?.antiLegionella?.timeout?.max}
                    disabled={isFieldsDisabled}
                  />
                {DHWSettingsData?.antiLegionella?.interval && (
                  <>
                    <Typography
                      sx={{
                        ...theme.typography[".text-sm-medium"],
                        color: theme.colors.colorsTextTextSecondary,
                        mb: theme.spacing.spacingSm,
                      }}
                    >
                      {t(
                        "pages.installationSettings.dhw.fieldsLabels.antiLegionellaFrequency"
                      )}
                    </Typography>
                    <SimpleDropdown
                      disabled={isFieldsDisabled}
                      onChange={(val) => {
                        console.log(val);

                        updateAntiLegionellaSettings(
                          {
                            ...DHWSettingsData?.antiLegionella?.interval,
                            value: Number(val),
                          },
                          "interval"
                        );
                      }}
                      label={`${t(
                        "pages.installationSettings.dhw.fieldsLabels.every"
                      )}`}
                      labelColor={theme.colors.colorsTextTextQuaternary}
                      currentValue={
                        DHWSettingsData?.antiLegionella?.interval?.value
                      }
                      dropdownOptions={weeksValuesArray}
                    />
                  </>
                )}
                {DHWSettingsData?.antiLegionella?.startHour &&
                  DHWSettingsData?.antiLegionella?.endHour && (
                    <>
                      <Typography
                        sx={{
                          ...theme.typography[".text-sm-medium"],
                          color: theme.colors.colorsTextTextSecondary,
                          mb: theme.spacing.spacingSm,
                        }}
                      >
                        {t(
                          "pages.installationSettings.dhw.fieldsLabels.antiLegionellaTimeRange"
                        )}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <TimeInput
                          label={`${t(
                            "pages.installationSettings.dhw.fieldsLabels.start"
                          )}`}
                          labelColor={theme.colors.colorsTextTextQuaternary}
                          currentHours={
                            DHWSettingsData?.antiLegionella?.startHour?.value?.toString() ||
                            "00"
                          }
                          onChange={(val) => {
                            updateAntiLegionellaSettings(
                              {
                                ...DHWSettingsData?.antiLegionella?.startHour,
                                value: Number(val),
                              },
                              "startHour"
                            );
                          }}
                          sx={{ m: `0 ${theme.spacing.spacingMd} 0 0` }}
                          disabled={isFieldsDisabled}
                        />
                        <TimeInput
                          label={`${t(
                            "pages.installationSettings.dhw.fieldsLabels.end"
                          )}`}
                          labelColor={theme.colors.colorsTextTextQuaternary}
                          currentHours={
                            DHWSettingsData?.antiLegionella?.endHour?.value?.toString() ||
                            "01"
                          }
                          onChange={(val) => {
                            updateAntiLegionellaSettings(
                              {
                                ...DHWSettingsData?.antiLegionella?.endHour,
                                value: Number(val),
                              },
                              "endHour"
                            );
                          }}
                          disabled={isFieldsDisabled}
                        />
                      </Box>
                      <Typography
                        sx={{
                          ...theme.typography[".text-sm-regular"],
                          color: theme.colors.colorsTextTextTertiary,
                          mt: "-10px",
                          mb: theme.spacing.spacingSm,
                        }}
                      >
                        {t(
                          "pages.installationSettings.dhw.fieldsLabels.antiLegionellaTimeRangeHint"
                        )}
                      </Typography>
                    </>
                  )}
              </>
            )}
        </>
      )}
      <UnsavedChangesModal
        tabName={t("pages.installationSettings.tabsNames.DHW")}
        condition={isSomethingChanged}
      />
      {isConfirmChangesModalOpen && (
        <ConfirmChangesModal
          isOpen={isConfirmChangesModalOpen}
          onCancel={() => setIsConfirmChangesModalOpen(false)}
          onOk={() => onConfirmSettigsChanges()}
          page={`${t("pages.installationSettings.tabsNames.DHW")}`}
          block={`${t("pages.installationSettings.tabsNames.DHW")}`}
          icon={
            <DHWIcon
              style={{ color: theme.colors.colorsForegroundFgWarningPrimary }}
              strokeWidth={2}
            />
          }
        >
          <DifferencesToShowInModal differencesToShow={differencesToShow} />
        </ConfirmChangesModal>
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onOkPress={() => setIsNotificationModalOpen(false)}
          icon={
            <DHWIcon
              style={{ color: theme.colors.colorsForegroundFgWarningPrimary }}
              strokeWidth={2}
            />
          }
        />
      )}
    </TabWrapper>
  );
}
