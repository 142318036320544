import { api } from "./api";
import { DeviceParam, DeviceParamState, SetParameterValuesParams } from "./models/parameters/parameters";

export const parametersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getParametersList: build.query<Array<DeviceParam>, { connectivityId: string }>(
      {
        query: ({ connectivityId }) => ({
          url: `nordflex/devices/${connectivityId}/parameters`,
        }),
        providesTags: (_result, _error, arg) => [{ type: 'Parameters', id: arg.connectivityId }],
      }
    ),
    getParameterDetails: build.query<DeviceParamState, { connectivityId: string; parameterName: string; }>(
      {
        query: ({ connectivityId, parameterName }) => ({
          url: `nordflex/devices/${connectivityId}/parameters/${parameterName}`,
        }),
        providesTags: (_result, _error, arg) => [{ type: 'ParameterDetails', id: arg.connectivityId + arg.parameterName }],
        onCacheEntryAdded: async (arg, { cacheDataLoaded, cacheEntryRemoved }) => {
          try {
            const timeoutPromise = new Promise((_, reject) =>
              setTimeout(() => reject(new Error('Timeout waiting for cacheDataLoaded')), 5000)
            );

            await Promise.race([cacheDataLoaded, timeoutPromise]);

            console.log('Cache data loaded:', arg);
          } catch (error) {
            console.error('Cache error:', error);
          } finally {
            await cacheEntryRemoved;
            console.log('Cache cleared:', arg);
          }
        },
      }
    ),
    setParameterValues: build.mutation<void, SetParameterValuesParams>({
      query: ({ connectivityId, parameters }) => ({
        url: `nordflex/devices/${connectivityId}/parameters/values`,
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
        },
        body: parameters,
      }),
      invalidatesTags: ['Parameters']
    })
  }),
});

export const {
  useGetParametersListQuery,
  useGetParameterDetailsQuery,
  useSetParameterValuesMutation,
  endpoints: { getParametersList, getParameterDetails, setParameterValues },
} = parametersApi;
