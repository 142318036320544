import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "../../../components/Button/Button";
import {
  useFindOrganizationMutation,
  useGetCountriesQuery,
  useGetOrganizationsQuery,
  useGetPartnerShipsQuery,
} from "../../../store/services/organizations";
import { useSnackbar } from "../../../components/Snackbar/SnackbarContext";
import useTheme from "../../../theme/hooks/useTheme";
import { ChevronDown } from "untitledui-js-base";
import { set } from "date-fns";
import {
  Country,
  OrganizationItemResponse,
  RelatedCompanyResponse,
} from "../../../store/services/models/organizations/organizations";
import { useShareInstallationMutation } from "../../../store/services/installation";
import { OrganizationTypes } from "../../../api/OrganizationsAPI";

type AssignInstallationDialogProperties = {
  onSuccess?: () => void;
  onError?: () => void;
  isSuperAdmin?: boolean;
  myOrganizationUuid?: string;
  installationName?: string;
};

type OpenModalProps = {
  installationUuid: string;
};

export type AssignInstallationDialogRef = {
  open: ({ installationUuid }: OpenModalProps) => void;
  close: () => void;
};

const AssignInstallationDialog = forwardRef<
  AssignInstallationDialogRef,
  AssignInstallationDialogProperties
>(({ onSuccess, isSuperAdmin = false, myOrganizationUuid, installationName }, ref) => {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.assignInstaller" });
  const { t: tActions } = useTranslation("cloud_ui", { keyPrefix: "actions" });
  const { t: tErrors } = useTranslation("cloud_ui", { keyPrefix: "errors" });
  const showSnackbar = useSnackbar();

  const [isOpened, setIsOpened] = useState(false);
  const [country, setCountry] = useState("");
  const [orgUuid, setOrgUuid] = useState("");
  const [installationUuid, setInstallationUuid] = useState("");
  const [availableOrganizations, setAvailableOrganizations] = useState<
    Array<OrganizationItemResponse | RelatedCompanyResponse>
  >([]);

  const { data: partnerShips } = useGetPartnerShipsQuery(
    { uuid: myOrganizationUuid! },
    { refetchOnMountOrArgChange: true, skip: !myOrganizationUuid },
  );
  const { data: countriesList } = useGetCountriesQuery();
  const { currentData: orgsList, isLoading: isOrgsListLoading } = useGetOrganizationsQuery(
    {
      country: country,
      types: [OrganizationTypes.INSTALLER, OrganizationTypes.DISTRIBUTOR],
    },
    { refetchOnFocus: true, refetchOnMountOrArgChange: true, refetchOnReconnect: true, skip: !isSuperAdmin },
  );
  const [shareInstallation] = useShareInstallationMutation();

  const resetSelectedCounty: Array<Country> = [
    { code: "all", name: t("fields.defaultValues.country") },
  ];

  useEffect(() => {
    if (!!myOrganizationUuid) {
      setAvailableOrganizations(partnerShips?.children || []);
    } else {
      if (orgsList) {
        setAvailableOrganizations(orgsList);
      }
    }
  }, [myOrganizationUuid, orgsList, partnerShips?.children]);

  const openModal = ({ installationUuid }: OpenModalProps) => {
    setIsOpened(true);
    setInstallationUuid(installationUuid);
  };
  const closeModal = () => {
    setIsOpened(false);
    setInstallationUuid("");
    setCountry("");
    setOrgUuid("");
  };

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  const handleOnSubmit = () => {
    shareInstallation({
      installationUuid: installationUuid,
      childOrgUuid: orgUuid,
      isSuperAdmin,
      orgUuid: orgUuid,
      installationName: installationName || "No name",
    })
      .unwrap()
      .then(() => {
        closeModal();
        !!onSuccess && onSuccess();
      })
      .catch(onError);
  };

  const handleCountyChange = (event: any) => {
    setCountry(event.target?.value || "");
  };

  const handleOrhNameChange = (event: any) => {
    setOrgUuid(event.target?.value || "");
  };

  const onError = () => {
    showSnackbar(tErrors("somethingWentWrong"), {
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      autoHideDuration: 3000,
      severity: "error",
    });
  };

  const renderLoader = () => (
    <Box sx={{ display: "flex", alignItems: "center", marginRight: theme.spacing.spacingLg }}>
      <CircularProgress
        size={16}
        sx={{
          color: theme.colors?.colorsTextTextPrimary,
        }}
      />
    </Box>
  );

  return (
    <Dialog open={isOpened} fullWidth={true} sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}>
      <DialogTitle
        sx={{
          paddingTop: `${theme.spacing.spacing3xl}`,
          paddingBottom: `${theme.spacing.spacingMd}`,
        }}
      >
        <Typography
          sx={{
            ...theme.typography[".text-lg-semibold"],
            color: theme.colors.colorsTextTextPrimary,
          }}
        >
          {t("title")}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: `0 ${theme.spacing.spacing3xl}`,
          mb: theme.spacing.spacing4xl,
        }}
      >
        <Typography
          sx={{
            ...theme.typography[".text-sm-regular"],
            color: theme.colors.colorsTextTextTertiary,
            whiteSpace: "pre-line",
          }}
        >
          {t("description")}
        </Typography>
        {!myOrganizationUuid && (
          <Box
            sx={{
              paddingTop: theme.spacing.spacingXl,
            }}
          >
            <InputLabel
              shrink
              sx={{
                color: theme.colors.colorsTextTextSecondary,
                fontWeight: 500,
              }}
            >
              {t("fields.labels.country")}
            </InputLabel>
            <FormControl fullWidth variant="outlined">
              <Select
                displayEmpty
                value={country}
                onChange={handleCountyChange}
                IconComponent={ChevronDown}
                sx={{
                  "& .MuiSelect-select": { padding: "0 10px" },
                  "& .MuiSelect-icon": { top: "11px" },
                  borderRadius: theme.radius.radiusMd,
                  height: 44,
                  color: theme.colors.colorsTextTextPlaceholder,
                  fontSize: "16px",
                }}
                renderValue={(selected) => {
                  return countriesList?.length
                    ? resetSelectedCounty
                        .concat(countriesList)
                        .find((option) => option.code === selected)?.name ||
                        t("fields.placeholders.country")
                    : t("fields.placeholders.country");
                }}
              >
                {countriesList?.length &&
                  resetSelectedCounty.concat(countriesList).map((option, index) => (
                    <MenuItem
                      key={`type-item-${index}`}
                      value={option.code}
                      sx={{
                        fontSize: "16px",
                        color: theme.colors.colorsTextTextPlaceholder,
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <Box
          sx={{
            paddingTop: theme.spacing.spacingXl,
          }}
        >
          <InputLabel
            shrink
            sx={{
              color: theme.colors.colorsTextTextSecondary,
              fontWeight: 500,
            }}
          >
            {t("fields.labels.organization")}
          </InputLabel>
          <FormControl fullWidth variant="outlined">
            <Select
              displayEmpty
              disabled={isOrgsListLoading}
              value={orgUuid}
              onChange={handleOrhNameChange}
              IconComponent={isOrgsListLoading ? () => renderLoader() : ChevronDown}
              sx={{
                "& .MuiSelect-select": { padding: "0 10px" },
                "& .MuiSelect-icon": { top: "11px" },
                borderRadius: theme.radius.radiusMd,
                height: 44,
                color: theme.colors.colorsTextTextPlaceholder,
                fontSize: "16px",
              }}
              renderValue={(selected) => {
                return (
                  availableOrganizations?.find((option) => option.uuid === selected)?.name ||
                  t("fields.placeholders.organization")
                );
              }}
            >
              {(availableOrganizations?.length > 0 &&
                availableOrganizations.map((option, index) => (
                  <MenuItem
                    key={`type-item-${index}`}
                    value={option.uuid}
                    sx={{
                      fontSize: "16px",
                      color: theme.colors.colorsTextTextPlaceholder,
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))) || (
                <MenuItem disabled value="">
                  {t("fields.emptyState.organization")}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
          justifyContent: "space-between",
        }}
      >
        <Button
          label={`${tActions("cancel")}`}
          onClick={closeModal}
          buttonType={ButtonType.SecondaryGray}
          sx={{
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
        <Button
          label={`${t("submitButton")}`}
          disabled={!orgUuid}
          onClick={handleOnSubmit}
          loadingInProgress={false}
          sx={{
            pointerEvents: false ? "none" : "initial",
            ml: "0 !important",
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
      </DialogActions>
    </Dialog>
  );
});

export default AssignInstallationDialog;
