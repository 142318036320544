import { TFunction } from "i18next";
import { IItemWithSection } from "../../../../components/DropdownScrollableWithCheckboxesAndSections/types";

export enum AvailableParametersEnum {
  additionalBufferTankHeatingSourceStatus = "additionalBufferTankHeatingSourceStatus",
  additionalDhwHeatingSourceStatus = "additionalDhwHeatingSourceStatus",
  additionalInlineHeatingSource = "additionalInlineHeatingSource",
  additionalInlineHeatingSourceStage1 = "additionalInlineHeatingSourceStage1",
  additionalInlineHeatingSourceStage2 = "additionalInlineHeatingSourceStage2",
  additionalInlineHeatingSourceStage3 = "additionalInlineHeatingSourceStage3",
  compressorDischargeTemperatureTD = "compressorDischargeTemperatureTD",
  compressorSpeed = "compressorSpeed",
  compressorSuctionTemperatureTS = "compressorSuctionTemperatureTS",
  condenserInletWaterTemperatureTUI = "condenserInletWaterTemperatureTUI",
  condenserOutletWaterTemperatureTUO = "condenserOutletWaterTemperatureTUO",
  condensingTemperatureTUP = "condensingTemperatureTUP",
  currentRoomTemperatureZ1 = "currentRoomTemperatureZ1",
  currentRoomTemperatureZ2 = "currentRoomTemperatureZ2",
  currentSeason = "currentSeason",
  defrostMode = "defrostMode",
  dhwAntiLegionellaState = "dhwAntiLegionellaState",
  dhwPreferredTemp = "dhwPreferredTemp",
  dhwTankTemperatureTWD = "dhwTankTemperatureTWD",
  eevOpeningP = "eevOpeningP",
  electricGridProtectionStatus = "electricGridProtectionStatus",
  evaporatingTemperatureTP = "evaporatingTemperatureTP",
  fanSpeed1 = "fanSpeed1",
  fanSpeed2 = "fanSpeed2",
  highPressurePd = "highPressurePd",
  hpState = "hpState",
  lowPressurePs = "lowPressurePs",
  operationStatus = "operationStatus",
  outdoorTemperature = "outdoorTemperature",
  outdoorUnitCurrent = "outdoorUnitCurrent",
  outdoorUnitVoltage = "outdoorUnitVoltage",
  preferredRoomTempZ1 = "preferredRoomTempZ1",
  preferredRoomTempZ2 = "preferredRoomTempZ2",
  quietMode = "quietMode",
  reducedModeState = "reducedModeState",
  requestedCompressorSpeed = "requestedCompressorSpeed",
  setPointTHC = "setPointTHC",
  sgReadyStatus = "sgReadyStatus",
  tv1SetPointTemp = "tv1SetPointTemp",
  tv1Temperature = "tv1Temperature",
  tv2SetPointTemp = "tv2SetPointTemp",
  tv2Temperature = "tv2Temperature",
  vacationModeState = "vacationModeState",
  waterFlowRate = "waterFlowRate",
  waterPumpP0Status = "waterPumpP0Status",
  waterPumpP1Status = "waterPumpP1Status",
  waterPumpP2Status = "waterPumpP2Status",
  waterTempTHC = "waterTempTHC",
}

export const getAvailableParametersList = ({
  t,
}: {
  t: TFunction;
}): Array<IItemWithSection> => {
  return [
    //systemOperation section
    {
      sectionTitle: t("availableParametersDropdown.titles.systemOperation"),
      itemsList: [
        {
          value: AvailableParametersEnum.hpState,
          label: t("availableParametersDropdown.parameters.hpState"),
        },
        {
          value: AvailableParametersEnum.operationStatus,
          label: t("availableParametersDropdown.parameters.operationStatus"),
        },
        {
          value: AvailableParametersEnum.outdoorTemperature,
          label: t("availableParametersDropdown.parameters.outdoorTemperature"),
        },
      ],
    },
    //systemOperation section end
    //season section
    {
      sectionTitle: t("availableParametersDropdown.titles.season"),
      itemsList: [
        {
          value: AvailableParametersEnum.currentSeason,
          label: t("availableParametersDropdown.parameters.currentSeason"),
        },
      ],
    },
    //season section end
    //zone1Operations section
    {
      sectionTitle: t("availableParametersDropdown.titles.zoneOperations", {
        zoneNumber: "1",
      }),
      itemsList: [
        {
          value: AvailableParametersEnum.preferredRoomTempZ1,
          label: t("availableParametersDropdown.parameters.preferredRoomTemp", {
            zoneNumber: "1",
          }),
        },
        {
          value: AvailableParametersEnum.currentRoomTemperatureZ1,
          label: t(
            "availableParametersDropdown.parameters.currentRoomTemperature",
            {
              zoneNumber: "1",
            }
          ),
        },
        {
          value: AvailableParametersEnum.tv1Temperature,
          label: t("availableParametersDropdown.parameters.tvTemperature", {
            zoneNumber: "1",
          }),
        },
        {
          value: AvailableParametersEnum.tv1SetPointTemp,
          label: t("availableParametersDropdown.parameters.tvSetPointTemp", {
            zoneNumber: "1",
          }),
        },
        {
          value: AvailableParametersEnum.waterPumpP1Status,
          label: t("availableParametersDropdown.parameters.waterPumpStatus", {
            zoneNumber: "1",
          }),
        },
      ],
    },
    //zone1Operations section end
    //zone2Operations section
    {
      sectionTitle: t("availableParametersDropdown.titles.zoneOperations", {
        zoneNumber: "2",
      }),
      itemsList: [
        {
          value: AvailableParametersEnum.preferredRoomTempZ2,
          label: t("availableParametersDropdown.parameters.preferredRoomTemp", {
            zoneNumber: "2",
          }),
        },
        {
          value: AvailableParametersEnum.currentRoomTemperatureZ2,
          label: t(
            "availableParametersDropdown.parameters.currentRoomTemperature",
            {
              zoneNumber: "2",
            }
          ),
        },
        {
          value: AvailableParametersEnum.tv2Temperature,
          label: t("availableParametersDropdown.parameters.tvTemperature", {
            zoneNumber: "2",
          }),
        },
        {
          value: AvailableParametersEnum.tv2SetPointTemp,
          label: t("availableParametersDropdown.parameters.tvSetPointTemp", {
            zoneNumber: "2",
          }),
        },
        {
          value: AvailableParametersEnum.waterPumpP2Status,
          label: t("availableParametersDropdown.parameters.waterPumpStatus", {
            zoneNumber: "2",
          }),
        },
      ],
    },
    //zone2Operations section end
    //DHW section
    {
      sectionTitle: t("availableParametersDropdown.titles.DHW"),
      itemsList: [
        {
          value: AvailableParametersEnum.dhwPreferredTemp,
          label: t("availableParametersDropdown.parameters.dhwPreferredTemp"),
        },
        {
          value: AvailableParametersEnum.dhwTankTemperatureTWD,
          label: t(
            "availableParametersDropdown.parameters.dhwTankTemperatureTWD"
          ),
        },
        {
          value: AvailableParametersEnum.dhwAntiLegionellaState,
          label: t(
            "availableParametersDropdown.parameters.dhwAntiLegionellaState"
          ),
        },
      ],
    },
    //DHW section end
    //Modes section
    {
      sectionTitle: t("availableParametersDropdown.titles.modes"),
      itemsList: [
        {
          value: AvailableParametersEnum.quietMode,
          label: t("availableParametersDropdown.parameters.quietMode"),
        },
        {
          value: AvailableParametersEnum.defrostMode,
          label: t("availableParametersDropdown.parameters.defrostMode"),
        },
        {
          value: AvailableParametersEnum.vacationModeState,
          label: t("availableParametersDropdown.parameters.vacationModeState"),
        },
        {
          value: AvailableParametersEnum.reducedModeState,
          label: t("availableParametersDropdown.parameters.reducedModeState"),
        },
      ],
    },
    //Modes section end
    //Hydraulic parameters section
    {
      sectionTitle: t("availableParametersDropdown.titles.hydraulicParameters"),
      itemsList: [
        {
          value: AvailableParametersEnum.waterTempTHC,
          label: t("availableParametersDropdown.parameters.waterTempTHC"),
        },
        {
          value: AvailableParametersEnum.setPointTHC,
          label: t("availableParametersDropdown.parameters.setPointTHC"),
        },
        {
          value: AvailableParametersEnum.condenserOutletWaterTemperatureTUO,
          label: t(
            "availableParametersDropdown.parameters.condenserOutletWaterTemperatureTUO"
          ),
        },
        {
          value: AvailableParametersEnum.condenserInletWaterTemperatureTUI,
          label: t(
            "availableParametersDropdown.parameters.condenserInletWaterTemperatureTUI"
          ),
        },
        {
          value: AvailableParametersEnum.waterPumpP0Status,
          label: t("availableParametersDropdown.parameters.waterPumpP0Status"),
        },
        {
          value: AvailableParametersEnum.waterFlowRate,
          label: t("availableParametersDropdown.parameters.waterFlowRate"),
        },
      ],
    },
    //Hydraulic parameters section end
    //Refrigerant parameters section
    {
      sectionTitle: t(
        "availableParametersDropdown.titles.refrigerantParameters"
      ),
      itemsList: [
        {
          value: AvailableParametersEnum.compressorSpeed,
          label: t("availableParametersDropdown.parameters.compressorSpeed"),
        },
        {
          value: AvailableParametersEnum.requestedCompressorSpeed,
          label: t(
            "availableParametersDropdown.parameters.requestedCompressorSpeed"
          ),
        },
        {
          value: AvailableParametersEnum.highPressurePd,
          label: t("availableParametersDropdown.parameters.highPressurePd"),
        },
        {
          value: AvailableParametersEnum.compressorDischargeTemperatureTD,
          label: t(
            "availableParametersDropdown.parameters.compressorDischargeTemperatureTD"
          ),
        },
        {
          value: AvailableParametersEnum.lowPressurePs,
          label: t("availableParametersDropdown.parameters.lowPressurePs"),
        },
        {
          value: AvailableParametersEnum.compressorSuctionTemperatureTS,
          label: t(
            "availableParametersDropdown.parameters.compressorSuctionTemperatureTS"
          ),
        },
        {
          value: AvailableParametersEnum.condensingTemperatureTUP,
          label: t(
            "availableParametersDropdown.parameters.condensingTemperatureTUP"
          ),
        },
        {
          value: AvailableParametersEnum.evaporatingTemperatureTP,
          label: t(
            "availableParametersDropdown.parameters.evaporatingTemperatureTP"
          ),
        },
        {
          value: AvailableParametersEnum.eevOpeningP,
          label: t("availableParametersDropdown.parameters.eevOpeningP"),
        },
        {
          value: AvailableParametersEnum.fanSpeed1,
          label: t("availableParametersDropdown.parameters.fanSpeed1"),
        },
        {
          value: AvailableParametersEnum.fanSpeed2,
          label: t("availableParametersDropdown.parameters.fanSpeed2"),
        },
      ],
    },
    //Refrigerant parameters section end
    //Electric grid protection section
    {
      sectionTitle: t(
        "availableParametersDropdown.titles.electricGridProtection"
      ),
      itemsList: [
        {
          value: AvailableParametersEnum.electricGridProtectionStatus,
          label: t(
            "availableParametersDropdown.parameters.electricGridProtectionStatus"
          ),
        },
      ],
    },
    //Electric grid protection section end
    //SGReady section
    {
      sectionTitle: t("availableParametersDropdown.titles.sgReady"),
      itemsList: [
        {
          value: AvailableParametersEnum.sgReadyStatus,
          label: t("availableParametersDropdown.parameters.sgReadyStatus"),
        },
      ],
    },
    //SGReady section end
    //Additional Heating Sources section
    {
      sectionTitle: t(
        "availableParametersDropdown.titles.additionalHeatingSources"
      ),
      itemsList: [
        {
          value: AvailableParametersEnum.additionalInlineHeatingSource,
          label: t(
            "availableParametersDropdown.parameters.additionalInlineHeatingSource"
          ),
        },
        {
          value: AvailableParametersEnum.additionalDhwHeatingSourceStatus,
          label: t(
            "availableParametersDropdown.parameters.additionalDhwHeatingSourceStatus"
          ),
        },
        {
          value:
            AvailableParametersEnum.additionalBufferTankHeatingSourceStatus,
          label: t(
            "availableParametersDropdown.parameters.additionalBufferTankHeatingSourceStatus"
          ),
        },
      ],
    },
    //Additional Heating Sources section end
    //Other section
    {
      sectionTitle: t("availableParametersDropdown.titles.other"),
      itemsList: [
        {
          value: AvailableParametersEnum.outdoorUnitCurrent,
          label: t("availableParametersDropdown.parameters.outdoorUnitCurrent"),
        },
        {
          value: AvailableParametersEnum.outdoorUnitVoltage,
          label: t("availableParametersDropdown.parameters.outdoorUnitVoltage"),
        },
      ],
    },
    //Other section end
  ];
};
