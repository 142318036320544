import { Box, Grid } from "@mui/material";
import React from "react";
import { SearchLG, XClose } from "untitledui-js-base";
import useTheme from "../../theme/hooks/useTheme";
import { Button } from "../Button/Button";
import { useTranslation } from "react-i18next";

type SearchBarComponentProps = {
  onSearchButtonClick: () => void;
  onClear: () => void;
  onSearchChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  searchValue?: string;
};

export default function SearchBarComponent({
  onSearchButtonClick,
  onClear,
  onSearchChange,
  searchValue,
}: SearchBarComponentProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");

  const { spacing, colors } = theme;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "320px",
          position: "relative",
          mr: spacing.spacingLg,
          input: {
            boxSizing: "border-box",
            height: "44px",
            width: "100%",
            padding: "0 35px 0 30px",
            borderRadius: "8px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            fontFamily: `"Inter", "Roboto", "Arial", sans-serif`,
            fontSize: "1rem",
            lineHeight: "1.4375em",
            color: colors.colorsTextTextPrimary,
            "&::placeholder": {
              color: colors.colorsTextTextPlaceholder,
              opacity: ".5",
            },
            "&:hover": {
              borderColor: colors.colorsTextTextPrimary,
            },
            "&:focus-visible": {
              outline: "-webkit-focus-ring-color auto 1px",
              outlineColor: "#6c6b6a",
            },
          },
        }}
      >
        <SearchLG
          style={{
            position: "absolute",
            top: "14px",
            left: "10px",
            color: colors.colorsTextTextPlaceholder,
            opacity: ".5",
          }}
          size="16"
          strokeWidth={2}
        />
        <input
          value={searchValue}
          placeholder={`${t("common_placeholders.search")}`}
          onChange={onSearchChange}
        />
        {searchValue && (
          <Box
            component={"button"}
            onClick={onClear}
            sx={{
              border: "none",
              backgroundColor: "initial",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: "5px",
              cursor: "pointer",
            }}
          >
            <XClose
              strokeWidth={2}
              size="18"
              style={{ color: colors.colorsForegroundFgQuaternary }}
            />
          </Box>
        )}
      </Box>
      <Button label={`${t("common_placeholders.search")}`} onClick={onSearchButtonClick} />
    </>
  );
}
