import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import { Box } from '@mui/material';
import { CheckCircle } from 'untitledui-js-base';
import useTheme from '../../theme/hooks/useTheme';

interface ISnackbarMessage {
  message: string;
  key: number;
  options?: {
    anchorOrigin?: {
      vertical: 'top' | 'bottom';
      horizontal: 'left' | 'center' | 'right';
    };
    autoHideDuration?: number;
    severity?: AlertColor; // 'success', 'info', 'warning', 'error'
  };
}

type ShowSnackbarFunction = (message: string, options?: ISnackbarMessage['options']) => void;

const SnackbarContext = createContext<ShowSnackbarFunction | undefined>(undefined);

export const useSnackbar = (): ShowSnackbarFunction => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const [snackPack, setSnackPack] = useState<ISnackbarMessage[]>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<ISnackbarMessage | undefined>(undefined);
  const theme = useTheme();

  const showSnackbar: ShowSnackbarFunction = useCallback((message, options = {}) => {
    const key = new Date().getTime();
    setSnackPack((prev) => [...prev, { message, key, options }]);
  }, []);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = (event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    if (snackPack.length) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else {
      setMessageInfo(undefined);
    }
  };

  const getColor = (type?: AlertColor) => {
    switch (type) {
      case 'error':
        return theme.colors.componentColorsUtilityErrorUtilityError500;
      case 'warning': 
        return theme.colors.componentColorsUtilityWarningUtilityWarning500;
      default:
        return theme.colors.colorsForegroundFgBrandPrimary;
    }
  }

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        key={messageInfo?.key}
        anchorOrigin={
          messageInfo?.options?.anchorOrigin || {
            vertical: "bottom",
            horizontal: "left",
          }
        }
        open={open}
        autoHideDuration={messageInfo?.options?.autoHideDuration || 6000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="outlined"
          icon={
            <Box
              sx={{
                borderRadius: 18,
                border: `solid 2px ${getColor(messageInfo?.options?.severity)}20`,
              }}
            >
              <Box
                sx={{
                  border: `solid 2px #fff`,
                  borderRadius: 18,
                }}
              >
                <Box
                  sx={{
                    border: `solid 2px ${getColor(messageInfo?.options?.severity)}60`,
                    borderRadius: 18,
                  }}
                >
                  <Box
                    sx={{
                      border: `solid 2px #fff`,
                      borderRadius: 18,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <CheckCircle size="18px" color={getColor(messageInfo?.options?.severity)} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          }
        >
          {messageInfo?.message}
        </MuiAlert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
