import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../../../store";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../store/services/installation";
import {
  IInstallationInstallerSettingsOthers,
  IInstallationSettingsByIdResponse,
  ISetInstallationSettings,
  ISetOthersInstallerSettings,
  SeasonSwitchEnum,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import {
  baseFindDifferences,
  cleanValuesForRequest,
  isEmpty,
} from "../../../../helpers/findDifferences";
import TabWrapper from "../../../../TabWrapper";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import { Box, Divider, Typography } from "@mui/material";
import SimpleDropdown from "../../../../components/SimpleDropdown/SimpleDropdown";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import { Tool02 } from "untitledui-js-base";
import DefaultChange from "../../../../components/ConfirmChangesModal/DefaultChage";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import NoParametersBlock from "../../../../NoParametersBlock";

export default function Others({ isInstallationOffline }: { isInstallationOffline?: boolean }) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const [isPoolingIntervalEnabled, setIsPoolingIntervalEnabled] = useState<boolean>(true);

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery(
    {
      uuid: id!,
      isRealData: params.get("dummyData") === "false",
    },
    {
      pollingInterval: isPoolingIntervalEnabled ? 60000 : 0,
    },
  );

  const [setInstallationSettings] = useSetInstallationSettingsMutation();
  const [isFieldsDisabled, setIsFieldsDisabled] = useState<boolean>(!!isInstallationOffline);
  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);

  const [othersSettingsData, setOthersSettingsData] =
    useState<IInstallationInstallerSettingsOthers>();
  const [initialOthersSettingsData, setInitialOthersSettingsData] =
    useState<IInstallationInstallerSettingsOthers>();

  const [initialSettings, setInitialSettings] = useState<IInstallationSettingsByIdResponse | null>(
    null,
  );

  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] = useState<boolean>(false);
  const [differencesToShow, setDifferencesToShow] = useState<any>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);

  const seasonSwitchDropdownOptions = [
    {
      label: t(
        "pages.installationSettings.installerSettings.others.seasonSwitchDropdownOptions.otdoorSensor",
      ),
      value: SeasonSwitchEnum.OUTDOOR_SENSOR,
    },
    {
      label: t(
        "pages.installationSettings.installerSettings.others.seasonSwitchDropdownOptions.digitalSignal",
      ),
      value: SeasonSwitchEnum.DIGITAL_SIGNAL,
    },
  ];

  const seasonSwitchValuesToShow = {
    [SeasonSwitchEnum.OUTDOOR_SENSOR]: t(
      "pages.installationSettings.installerSettings.others.seasonSwitchDropdownOptions.otdoorSensor",
    ),
    [SeasonSwitchEnum.DIGITAL_SIGNAL]: t(
      "pages.installationSettings.installerSettings.others.seasonSwitchDropdownOptions.digitalSignal",
    ),
  };

  useEffect(() => {
    setIsFieldsDisabled(!!isInstallationOffline);
  }, [isInstallationOffline]);

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
      setOthersSettingsData(
        JSON.parse(JSON.stringify(settingsParameters.installerSettings?.others || {})),
      );
      setInitialOthersSettingsData(
        JSON.parse(JSON.stringify(settingsParameters.installerSettings?.others || {})),
      );
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (JSON.stringify(othersSettingsData) !== JSON.stringify(initialOthersSettingsData)) {
      setIsSomethingChanged(true);
      setIsPoolingIntervalEnabled(false);
    } else {
      setIsSomethingChanged(false);
      setIsPoolingIntervalEnabled(true);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [othersSettingsData]);

  const updateSeasonSwitch = (value: any) => {
    const updatedSettings = { ...othersSettingsData };
    updatedSettings.seasonSwitch = value;
    setOthersSettingsData(updatedSettings as IInstallationInstallerSettingsOthers);
  };

  const updateWaterPumpP0Speed = (field: string, value: any) => {
    if (othersSettingsData?.waterPumpP0Speed) {
      const updatedSettings = { ...othersSettingsData };
      (updatedSettings.waterPumpP0Speed as any)[field] = value;
      setOthersSettingsData(updatedSettings as IInstallationInstallerSettingsOthers);
    }
  };

  const onDiscardChanges = () => {
    setOthersSettingsData(JSON.parse(JSON.stringify(initialOthersSettingsData)));
    setIsPoolingIntervalEnabled(true);
  };

  const onSaveChanges = () => {
    const differences = baseFindDifferences(initialOthersSettingsData!, othersSettingsData!);
    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    let newSettings: Partial<ISetOthersInstallerSettings> =
      cleanValuesForRequest(differencesToShow);

    return {
      installerSettings: {
        others: newSettings,
      },
    };
  };

  const onConfirmSettigsChanges = () => {
    const updatedSettings = { ...initialSettings };
    updatedSettings.installerSettings = {
      ...(updatedSettings.installerSettings as any),
      others: othersSettingsData,
    };
    setIsConfirmChangesModalOpen(false);
    const requestBody = createRequest();
    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id!, isRealData: params.get("dummyData") === "false" },
            (draftSettings) => {
              Object.assign(draftSettings, updatedSettings);
            },
          ),
        );
        setIsNotificationModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPoolingIntervalEnabled(true);
      });
  };

  if (
    !settings ||
    isEmpty(settings.installerSettings.others || isEmpty(othersSettingsData)) ||
    (isEmpty(othersSettingsData?.waterPumpP0Speed) && !othersSettingsData?.seasonSwitch)
  ) {
    return <NoParametersBlock />;
  }

  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
    >
      <MainTitle title={t("pages.installationSettings.installerSettings.others.mainTitle")} />
      <Box sx={{ mt: theme.spacing.spacingXl }} />

      {othersSettingsData?.seasonSwitch && (
        <SimpleDropdown
          disabled={isFieldsDisabled}
          label={`${t(
            "pages.installationSettings.installerSettings.others.fieldsLabels.seasonSwitch",
          )}`}
          currentValue={othersSettingsData?.seasonSwitch}
          dropdownOptions={seasonSwitchDropdownOptions}
          onChange={(val) => updateSeasonSwitch(val)}
          sx={{
            ".MuiInputBase-root ": {
              width: "100%",
              maxWidth: "415px",
            },
          }}
        />
      )}
      {(othersSettingsData?.waterPumpP0Speed?.dhw?.value ||
        othersSettingsData?.waterPumpP0Speed?.cooling?.value ||
        othersSettingsData?.waterPumpP0Speed?.heating?.value) && (
        <>
          <Divider
            sx={{
              width: "100%",
              borderColor: theme.colors.colorsBorderBorderSecondary,
            }}
          />
          <SecondaryTitle
            title={`${t("pages.installationSettings.installerSettings.others.secondaryTitle")}`}
            variant="md"
          />
        </>
      )}
      <NumberInput
        initialValue={othersSettingsData?.waterPumpP0Speed?.dhw?.value}
        changeValue={(val) =>
          updateWaterPumpP0Speed("dhw", {
            ...othersSettingsData?.waterPumpP0Speed.dhw,
            value: val,
          })
        }
        decimalPlaces={0}
        unit={"%"}
        step={1}
        label={`${t("pages.installationSettings.installerSettings.others.fieldsLabels.dhw")}`}
        min={othersSettingsData?.waterPumpP0Speed?.dhw?.min}
        max={othersSettingsData?.waterPumpP0Speed?.dhw?.max}
        inputWidth="72px"
        disabled={isFieldsDisabled}
      />
      <NumberInput
        initialValue={othersSettingsData?.waterPumpP0Speed?.cooling?.value}
        changeValue={(val) =>
          updateWaterPumpP0Speed("cooling", {
            ...othersSettingsData?.waterPumpP0Speed.cooling,
            value: val,
          })
        }
        decimalPlaces={0}
        unit={"%"}
        step={1}
        label={`${t("pages.installationSettings.installerSettings.others.fieldsLabels.cooling")}`}
        min={othersSettingsData?.waterPumpP0Speed?.cooling?.min}
        max={othersSettingsData?.waterPumpP0Speed?.cooling?.max}
        inputWidth="72px"
        disabled={isFieldsDisabled}
      />
      <NumberInput
        initialValue={othersSettingsData?.waterPumpP0Speed?.heating?.value}
        changeValue={(val) =>
          updateWaterPumpP0Speed("heating", {
            ...othersSettingsData?.waterPumpP0Speed.heating,
            value: val,
          })
        }
        decimalPlaces={0}
        unit={"%"}
        step={1}
        label={`${t("pages.installationSettings.installerSettings.others.fieldsLabels.heating")}`}
        min={othersSettingsData?.waterPumpP0Speed?.heating?.min}
        max={othersSettingsData?.waterPumpP0Speed?.heating?.max}
        inputWidth="72px"
        disabled={isFieldsDisabled}
      />
      <UnsavedChangesModal
        tabName={t("pages.installationSettings.tabsNames.installerOthers")}
        condition={isSomethingChanged}
      />
      {isConfirmChangesModalOpen && (
        <ConfirmChangesModal
          isOpen={isConfirmChangesModalOpen}
          onCancel={() => setIsConfirmChangesModalOpen(false)}
          onOk={() => onConfirmSettigsChanges()}
          page={`${t("pages.installationSettings.tabsNames.installerOthers")}`}
          block={`${t("pages.installationSettings.tabsNames.installerSettings")}`}
          // showHeatPumpNote={differencesToShow.state}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        >
          {/* <DifferencesToShowInModal differencesToShow={differencesToShow} /> */}
          <>
            {differencesToShow.seasonSwitch && (
              <Typography
                sx={{
                  ...theme.typography[".text-md-semibold"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingXl,
                }}
              >
                {t("pages.installationSettings.installerSettings.others.mainTitle")}
              </Typography>
            )}
            {differencesToShow?.seasonSwitch && (
              <DefaultChange
                isVertical
                changedValue={
                  seasonSwitchValuesToShow[
                    differencesToShow?.seasonSwitch.newValue as SeasonSwitchEnum
                  ]
                }
                previousValue={
                  seasonSwitchValuesToShow[
                    differencesToShow?.seasonSwitch.previousValue as SeasonSwitchEnum
                  ]
                }
                title={`${t(
                  "pages.installationSettings.installerSettings.others.fieldsLabels.seasonSwitch",
                )}`}
              />
            )}
            {differencesToShow.seasonSwitch && (
              <Divider
                sx={{
                  width: "100%",
                  borderColor: theme.colors.colorsBorderBorderSecondary,
                }}
              />
            )}
            {differencesToShow.waterPumpP0Speed && (
              <Typography
                sx={{
                  ...theme.typography[".text-md-semibold"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingXl,
                  mt: theme.spacing.spacingXl,
                }}
              >
                {t("pages.installationSettings.installerSettings.others.secondaryTitle")}
              </Typography>
            )}
            {differencesToShow?.waterPumpP0Speed?.dhw && (
              <DefaultChange
                changedValue={differencesToShow?.waterPumpP0Speed.dhw.value.newValue + "%"}
                previousValue={differencesToShow?.waterPumpP0Speed.dhw.value.previousValue + "%"}
                title={`${t(
                  "pages.installationSettings.installerSettings.others.fieldsLabels.dhw",
                )}`}
              />
            )}
            {differencesToShow?.waterPumpP0Speed?.cooling && (
              <DefaultChange
                changedValue={differencesToShow?.waterPumpP0Speed.cooling.value.newValue + "%"}
                previousValue={
                  differencesToShow?.waterPumpP0Speed.cooling.value.previousValue + "%"
                }
                title={`${t(
                  "pages.installationSettings.installerSettings.others.fieldsLabels.cooling",
                )}`}
              />
            )}
            {differencesToShow?.waterPumpP0Speed?.heating && (
              <DefaultChange
                changedValue={differencesToShow?.waterPumpP0Speed.heating.value.newValue + "%"}
                previousValue={
                  differencesToShow?.waterPumpP0Speed.heating.value.previousValue + "%"
                }
                title={`${t(
                  "pages.installationSettings.installerSettings.others.fieldsLabels.heating",
                )}`}
              />
            )}
          </>
        </ConfirmChangesModal>
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onOkPress={() => setIsNotificationModalOpen(false)}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        />
      )}
    </TabWrapper>
  );
}
