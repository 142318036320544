import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppDispatch, useTypedSelector } from "../../../../../../store";
import {
  installationApi,
  useGetInstallationDetailsQuery,
  useGetInstallationSettingsQuery,
  useGetInstallationStateQuery,
  useSetInstallationSettingsMutation,
  useUnpairInstallationMutation,
} from "../../../../../../store/services/installation";
import {
  IInstallationSettingsByIdResponse,
  IInstallationUserSettings,
  ISetInstallationSettings,
  ISetUserSettings,
} from "../../../../../../store/services/models/installations/installationSettings";
import { baseFindDifferences, cleanValuesForRequest, isEmpty } from "../../helpers/findDifferences";
import TabWrapper from "../../TabWrapper";
import MainTitle from "../../components/MainTitle/MainTitle";
import SecondaryTitle from "../../components/SecondaryTitle/SecondaryTitle";
import { Box, Divider, Typography } from "@mui/material";
import { Button, ButtonType } from "../../../../../../components/Button/Button";
import NumberInput from "../../components/NumberInput/NumberInput";
import UnsavedChangesModal from "../../components/UnsavedChangesModal/UnsavedChangesModal";
import ConfirmChangesModal from "../../components/ConfirmChangesModal/ConfirmChangesModal";
import DefaultChange from "../../components/ConfirmChangesModal/DefaultChage";
import { UserEdit } from "untitledui-js-base";
import NotificationModal from "../../components/NotificationModal/NotificationModal";
import NoParametersBlock from "../../NoParametersBlock";
import UnpairModal from "../../components/UnpairModal/UnpairModal";
import { selectUserOrganizationInfo } from "../../../../../../store/reducers/organizationSlice";

export default function UserSettings({
  isInstallationOffline,
}: {
  isInstallationOffline?: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.userSettings",
  });
  const { t: mainTranslation } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const [isPoolingIntervalEnabled, setIsPoolingIntervalEnabled] = useState<boolean>(true);

  const currentUserOrganizationInfo = useTypedSelector(selectUserOrganizationInfo);

  const {
    data: installationDetails,
    isFetching: isFetchingDetails,
    isLoading: isLoadingDetails,
    refetch: refetchDetails,
  } = useGetInstallationDetailsQuery(
    {
      installationUuid: id!,
      organizationUuid: currentUserOrganizationInfo?.uuid,
    },
    {
      pollingInterval: isPoolingIntervalEnabled ? 60000 : 0,
    },
  );

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery(
    {
      uuid: id!,
      isRealData: params.get("dummyData") === "false",
    },
    {
      pollingInterval: isPoolingIntervalEnabled ? 60000 : 0,
    },
  );

  const [setInstallationSettings] = useSetInstallationSettingsMutation();

  const [unpairInstallation] = useUnpairInstallationMutation();

  const [isFieldsDisabled, setIsFieldsDisabled] = useState<boolean>(!!isInstallationOffline);

  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);

  const [userSettingsData, setUserSettingsData] = useState<IInstallationUserSettings>();
  const [initalUserSettingsData, setInitalUserSettingsData] = useState<IInstallationUserSettings>();
  const [initialSettings, setInitialSettings] = useState<IInstallationSettingsByIdResponse | null>(
    null,
  );

  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] = useState<boolean>(false);
  const [differencesToShow, setDifferencesToShow] = useState<any>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);
  const [isUnpairModalOpen, setIsUnpairModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsFieldsDisabled(!!isInstallationOffline);
  }, [isInstallationOffline]);

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setUserSettingsData(JSON.parse(JSON.stringify(settingsParameters.userSettings || {})));
      setInitalUserSettingsData(JSON.parse(JSON.stringify(settingsParameters.userSettings || {})));
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (JSON.stringify(userSettingsData) !== JSON.stringify(initalUserSettingsData)) {
      setIsSomethingChanged(true);
      setIsPoolingIntervalEnabled(false);
    } else {
      setIsSomethingChanged(false);
      setIsPoolingIntervalEnabled(true);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [userSettingsData]);

  const onDiscardChanges = () => {
    setUserSettingsData(JSON.parse(JSON.stringify(initalUserSettingsData)));
    setIsPoolingIntervalEnabled(true);
  };

  const onSaveChanges = () => {
    const differences = baseFindDifferences(initalUserSettingsData!, userSettingsData!);

    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    let newUserSettings: Partial<ISetUserSettings> = cleanValuesForRequest(differencesToShow);

    return {
      userSettings: newUserSettings,
    };
  };

  const onConfirmSettigsChanges = () => {
    const updatedSettings = { ...initialSettings };
    updatedSettings.userSettings = userSettingsData;
    setIsConfirmChangesModalOpen(false);
    const requestBody = createRequest();
    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id!, isRealData: params.get("dummyData") === "false" },
            (draftSettings) => {
              Object.assign(draftSettings, updatedSettings);
            },
          ),
        );
        setIsNotificationModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPoolingIntervalEnabled(true);
      });
  };

  if (!settings || isEmpty(settings.userSettings) || isEmpty(userSettingsData)) {
    return <NoParametersBlock />;
  }

  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
    >
      <MainTitle title={t("mainTitle")} />
      <>
        <SecondaryTitle title={t("userAppTitle")} variant="md" />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!installationDetails?.sharingInfo?.clientOrgUuid ? (
            <Typography
              sx={{
                ...theme.typography[".text-sm-medium"],
                color: theme.colors.colorsTextTextPrimary,
                mr: theme.spacing.spacingLg,
              }}
            >
              {t("unpaired")}
            </Typography>
          ) : (
            <Button
              buttonType={ButtonType.ErrorPrimary}
              label={`${t("unpairButtonText")}`}
              onClick={() => setIsUnpairModalOpen(true)}
              disabled={isFieldsDisabled}
            />
          )}
        </Box>
      </>
      <>
        <Divider
          sx={{
            width: "100%",
            borderColor: theme.colors.colorsBorderBorderSecondary,
            mt: theme.spacing.spacingXl,
            mb: theme.spacing.spacingXl,
          }}
        />
        <SecondaryTitle title={t("seasonStartStopConditionsTitle")} variant="md" />
      </>
      <NumberInput
        label={`${t("fieldsLabels.heatingSeasonStartStop")}`}
        hint={`${t("fieldsHints.heatingSeasonStartStop")}`}
        initialValue={userSettingsData?.heatingSeasonStartTemp.value}
        decimalPlaces={1}
        min={userSettingsData?.heatingSeasonStartTemp?.min}
        max={userSettingsData?.coolingSeasonStartTemp.value! - 3}
        step={0.5}
        changeValue={(val) => {
          const updatedUserSettings = { ...userSettingsData };
          updatedUserSettings.heatingSeasonStartTemp = val
            ? {
                ...updatedUserSettings.heatingSeasonStartTemp!,
                value: val,
              }
            : userSettingsData?.heatingSeasonStartTemp;
          setUserSettingsData(updatedUserSettings as IInstallationUserSettings);
        }}
        disabled={isFieldsDisabled}
      />
      <>
        <NumberInput
          label={`${t("fieldsLabels.coolingSeasonStartStop")}`}
          hint={`${t("fieldsHints.coolingSeasonStartStop")}`}
          initialValue={userSettingsData?.coolingSeasonStartTemp.value}
          decimalPlaces={1}
          min={userSettingsData?.heatingSeasonStartTemp.value! + 3}
          max={userSettingsData?.coolingSeasonStartTemp?.max}
          step={0.5}
          changeValue={(val) => {
            const updatedUserSettings = { ...userSettingsData };
            updatedUserSettings.coolingSeasonStartTemp = val
              ? {
                  ...updatedUserSettings.coolingSeasonStartTemp!,
                  value: val,
                }
              : userSettingsData?.coolingSeasonStartTemp;
            setUserSettingsData(updatedUserSettings as IInstallationUserSettings);
          }}
          disabled={isFieldsDisabled}
        />
        <Typography
          sx={{
            ...theme.typography[".text-sm-regular"],
            color: theme.colors.colorsTextTextTertiary,
          }}
        >
          {t("fieldsHints.differenceHint")}
        </Typography>
      </>
      <UnsavedChangesModal
        tabName={mainTranslation("pages.installationSettings.tabsNames.userSettings")}
        condition={isSomethingChanged}
      />
      {isConfirmChangesModalOpen && (
        <ConfirmChangesModal
          isOpen={isConfirmChangesModalOpen}
          onCancel={() => setIsConfirmChangesModalOpen(false)}
          onOk={() => onConfirmSettigsChanges()}
          page={`${mainTranslation("pages.installationSettings.tabsNames.userSettings")}`}
          block={`${mainTranslation("pages.installationSettings.tabsNames.userSettings")}`}
          icon={
            <UserEdit
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        >
          <>
            {differencesToShow?.paired && (
              <DefaultChange
                changedValue={t(differencesToShow?.paired.newValue ? "paired" : "unpaired")}
                previousValue={t(differencesToShow?.paired.previousValue ? "paired" : "unpaired")}
                title={`${t("userAppTitle")}`}
              />
            )}
            {differencesToShow?.heatingSeasonStartTemp && (
              <DefaultChange
                changedValue={differencesToShow.heatingSeasonStartTemp.value.newValue + "°C"}
                previousValue={differencesToShow.heatingSeasonStartTemp.value.previousValue + "°C"}
                title={`${t("fieldsLabels.heatingSeasonStartStop")}`}
              />
            )}
            {differencesToShow?.coolingSeasonStartTemp && (
              <DefaultChange
                changedValue={differencesToShow.coolingSeasonStartTemp.value.newValue + "°C"}
                previousValue={differencesToShow.coolingSeasonStartTemp.value.previousValue + "°C"}
                title={`${t("fieldsLabels.coolingSeasonStartStop")}`}
              />
            )}
          </>
        </ConfirmChangesModal>
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onOkPress={() => setIsNotificationModalOpen(false)}
          icon={
            <UserEdit
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        />
      )}
      {isUnpairModalOpen && (
        <UnpairModal
          isOpen={isUnpairModalOpen}
          onCancel={() => setIsUnpairModalOpen(false)}
          onOk={() => {
            unpairInstallation({
              installationUuid: id!,
            })
              .unwrap()
              .then(() => {
                setIsUnpairModalOpen(false);
                // refetchSettings();
                refetchDetails();
              })
              .catch((error) => {});
          }}
        />
      )}
    </TabWrapper>
  );
}
