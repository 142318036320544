import React from "react";
import useTheme, { ESThemeType } from "../theme/hooks/useTheme";
import { Box } from "@mui/material";
import { SVGComponentProps } from "untitledui-js-base/dist/template";

interface BadgeProps {
  text: string;
  type: "warning" | "error" | "success";
  Icon?: React.FC<SVGComponentProps>;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Badge: React.FC<BadgeProps> = ({ text, type, Icon, onClick }: BadgeProps) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const getIconColor = () => {
    switch (type) {
      case "warning":
        return theme.colors.componentColorsUtilityWarningUtilityWarning500;
      case "success":
        return theme.colors.componentColorsUtilitySuccessUtilitySuccess500;
      default:
        return theme.colors.componentColorsUtilityErrorUtilityError500;
    }
  };

  const getBadgeColor = () => {
    switch (type) {
      case "warning":
        return styles.badgeWarningStyle;
      case "success":
        return styles.badgeSuccessStyle;
      default:
        return styles.badgeErrorStyle;
    }
  };

  return (
    <Box
      sx={[styles.badgeStyle, getBadgeColor(), !!onClick && { cursor: "pointer" }]}
      onClick={onClick}
    >
      {Icon && (
        <Icon
          size="12"
          style={{
            marginRight: theme.spacing.spacingXs,
            color: getIconColor(),
          }}
          strokeWidth={2}
        />
      )}
      {text}
    </Box>
  );
};

const getStyles = ({ colors, spacing, typography, radius }: ESThemeType) => {
  return {
    badgeStyle: {
      ...typography[".text-sm-medium"],
      height: "24px",
      maxWidth: "fit-content",
      display: "flex",
      alignItems: "center",
      padding: `${spacing.spacingXxs} ${spacing.spacingMd} ${spacing.spacingXxs} ${spacing.spacingMd}`,
      borderRadius: "12px",
      border: `1px solid`,
    },
    badgeWarningStyle: {
      borderColor: colors.componentColorsUtilityWarningUtilityWarning200,
      backgroundColor: colors.componentColorsUtilityWarningUtilityWarning50,
      color: colors.componentColorsUtilityWarningUtilityWarning700,
    },
    badgeErrorStyle: {
      borderColor: colors.componentColorsUtilityErrorUtilityError200,
      backgroundColor: colors.componentColorsUtilityErrorUtilityError50,
      color: colors.componentColorsUtilityErrorUtilityError700,
    },
    badgeSuccessStyle: {
      borderColor: colors.componentColorsUtilitySuccessUtilitySuccess200,
      backgroundColor: colors.componentColorsUtilitySuccessUtilitySuccess50,
      color: colors.componentColorsUtilitySuccessUtilitySuccess700,
    },
    dotStyle: {
      width: "6px",
      height: "6px",
      borderRadius: "50%",
      backgroundColor: colors.componentColorsUtilityBrandUtilityBrand600,
      mr: spacing.spacingSm,
    },
  };
};

export default Badge;
