import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import SimpleSideMenu, {
  ISideMenuItem,
} from "../../../../components/SimpleSideMenu/SimpleSideMenu";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router";
import { HomeSmile, Tool02, UserEdit, WiFiOff } from "untitledui-js-base";

import { ReactComponent as DHWIcon } from "../RealTimeData/SectionIcons/DHWIcon.svg";
import { ReactComponent as SystemOperationIcon } from "../RealTimeData/SectionIcons/SystemOperationIcon.svg";
import useTheme from "../../../../theme/hooks/useTheme";
import PrivateRoute from "../../../../components/PrivateRoute/PrivateRoute";
import Zones from "./Tabs/ZonesTab/Zones";
import DomesticHotWater from "./Tabs/DomesticHotWaterTab/DomesticHotWater";
import Operation from "./Tabs/OperationTab/Operation";
import UserSettings from "./Tabs/UserSettingsTab/UserSettings";
import InstallerSettings from "./Tabs/InstallerSettingsTab/InstallerSettings";
import { useTypedSelector } from "../../../../store";
import { selectSelectedInstallationDetails } from "../../../../store/reducers/installationSlice";
import {
  ActiveOrInactiveForDHWEnum,
  OnlineStatus,
} from "../../../../store/services/models/installations/installationsCommon";
import EmptyInstallationPage from "../../EmptyPage";
import { useGetInstallationSettingsQuery } from "../../../../store/services/installation";

export default function SettingsPage({ baseUrl }: { baseUrl: string }) {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const { id } = useParams();

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery({
    uuid: id!,
    isRealData: params.get("dummyData") === "false",
  });

  const selectedInstallationDetails = useTypedSelector(selectSelectedInstallationDetails);

  const [menuItems, setMenuItems] = useState<Array<ISideMenuItem>>([]);
  useEffect(() => {
    if (location.pathname.endsWith("settings")) {
      navigate(`${baseUrl}/installation-settings-zones${location.search}`);
    }
    if (id) {
      setMenuItems([
        {
          label: t("pages.installationSettings.sideMenu.zones"),
          href: `${baseUrl}/installation-settings-zones${location.search}`,
          icon: <HomeSmile size="24" style={{ color: "inherit" }} strokeWidth={2} />,
        },
        {
          label: t("pages.installationSettings.sideMenu.DHW"),
          href: `${baseUrl}/installation-settings-dhw${location.search}`,
          icon: <DHWIcon style={{ color: "inherit" }} strokeWidth={2} />,
          hide:
            settings?.installerSettings?.commissioning?.dhw?.active !==
            ActiveOrInactiveForDHWEnum.ACTIVE,
        },
        {
          label: t("pages.installationSettings.sideMenu.operation"),
          href: `${baseUrl}/installation-settings-operation${location.search}`,
          icon: <SystemOperationIcon style={{ color: "inherit" }} strokeWidth={2} />,
        },
        {
          label: t("pages.installationSettings.sideMenu.userSettings"),
          href: `${baseUrl}/installation-settings-user-settings${location.search}`,
          icon: <UserEdit size="24" style={{ color: "inherit" }} strokeWidth={2} />,
        },
        {
          label: t("pages.installationSettings.sideMenu.installerSettings"),
          href: `${baseUrl}/installation-settings-installer-settings${location.search}`,
          icon: <Tool02 size="24" style={{ color: "inherit" }} strokeWidth={2} />,
        },
      ]);
    }
  }, [id, settings]);

  if (
    selectedInstallationDetails?.lastCommunicationDate === undefined &&
    selectedInstallationDetails?.onlineStatus === OnlineStatus.OFFLINE
  ) {
    return (
      <EmptyInstallationPage
        icon={
          <WiFiOff
            size="20"
            style={{
              color:
                theme.colors
                  .componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgGray,
            }}
            strokeWidth={2}
          />
        }
        title={t("pages.installationBornOffline.titles.settings")}
        description={t("pages.installationBornOffline.descriptions.settings")}
      />
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          height: "calc((var(--vh, 1vh) * 100) - 241px)",
          overflowY: "hidden",
          mr: `-${theme.spacing.spacing2xl}`,
        }}
      >
        <Box
          sx={{
            width: "280px",
            height: "100%",
            borderRight: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
          }}
        >
          <SimpleSideMenu menuItems={menuItems} />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Routes>
            <Route
              path="/installation-settings-zones/*"
              element={
                <PrivateRoute
                  element={
                    <Zones
                      isInstallationOffline={
                        selectedInstallationDetails?.onlineStatus === OnlineStatus.OFFLINE
                      }
                      baseUrl={`${baseUrl}/installation-settings-zones`}
                    />
                  }
                  rolesToCheck={["INSTALLATION_READ"]}
                />
              }
            />
            <Route
              path="/installation-settings-dhw"
              element={
                <PrivateRoute
                  element={
                    <DomesticHotWater
                      isInstallationOffline={
                        selectedInstallationDetails?.onlineStatus === OnlineStatus.OFFLINE
                      }
                    />
                  }
                  rolesToCheck={["INSTALLATION_READ"]}
                />
              }
            />
            <Route
              path="/installation-settings-operation"
              element={
                <PrivateRoute
                  element={
                    <Operation
                      isInstallationOffline={
                        selectedInstallationDetails?.onlineStatus === OnlineStatus.OFFLINE
                      }
                    />
                  }
                  rolesToCheck={["INSTALLATION_READ"]}
                />
              }
            />
            <Route
              path="/installation-settings-user-settings"
              element={
                <PrivateRoute
                  element={
                    <UserSettings
                      isInstallationOffline={
                        selectedInstallationDetails?.onlineStatus === OnlineStatus.OFFLINE
                      }
                    />
                  }
                  rolesToCheck={["INSTALLATION_READ"]}
                />
              }
            />
            <Route
              path="/installation-settings-installer-settings/*"
              element={
                <PrivateRoute
                  element={
                    <InstallerSettings
                      isInstallationOffline={
                        selectedInstallationDetails?.onlineStatus === OnlineStatus.OFFLINE
                      }
                      baseUrl={`${baseUrl}/installation-settings-installer-settings`}
                    />
                  }
                  rolesToCheck={["INSTALLATION_READ"]}
                />
              }
            />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}
