import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Container,
  Tooltip,
  Avatar,
  AppBar,
  Divider,
  Button,
  SxProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useTheme from "../../theme/hooks/useTheme";
import { UsersAPI } from "../../api/UsersAPI";
import { jwtDecode } from "jwt-decode";
import { IOrganizationDetails, OrganizationsAPI } from "../../api/OrganizationsAPI";
import { CornerUpLeft, Logout04, Menu02, UserEdit } from "untitledui-js-base";
import EditProfileDialog from "./EditProfileModal/EditProfileDialog";
import { getAuthToken } from "../../util/AuthenticationUtil";
import AuthenticationAPI from "../../api/AuthenticationAPI";
import useResize from "../../hooks/useResize";
import { useAppDispatch, useTypedSelector } from "../../store";
import {
  selectUserOrganizationInfo,
  setUserOrganizationInfo,
} from "../../store/reducers/organizationSlice";

export interface IMainNavigationItem {
  label: string;
  href: string;
  icon?: any;
  isExternalLink?: boolean;
  subItems?: IMainNavigationItem[];
}

export interface IMainNavigationGroup {
  groupName?: string;
  groupItems: Array<IMainNavigationItem>;
}

export interface IProps {
  onLogout: () => void;
  userDetails?: any;
  setIsSideBarOpen?: any;
  isSideBarOpen: boolean;
}

export const MainNavigation = ({
  onLogout,
  userDetails,
  setIsSideBarOpen,
  isSideBarOpen,
}: IProps) => {
  const navigate = useNavigate();
  const usersApi = new UsersAPI(navigate);
  const organizationsApi = new OrganizationsAPI(navigate);
  const authApi = new AuthenticationAPI(navigate);
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();
  const customTheme = useTheme();
  const { height, width } = useResize();

  const dispatch = useAppDispatch();
  const currentUserOrganizationInfo = useTypedSelector(
    selectUserOrganizationInfo
  );

  const mobileButtonStyles: SxProps = {
    position: "fixed",
    bottom: "10px",
    left: "50%",
    transform: "transtalteX(-50%)",
  };

  const [_navigationSelectedItem, setNavigationSelectedItem] = React.useState(0);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const [isEditProfileDialogOpen, setIsEditProfileDialogOpen] =
    useState<boolean>(false);

  const [currentUserUuid, setCurrentUserUuid] = useState<string>();

  const [userInfo, setUserInfo] = useState<any>();

  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);

  const [isMobileScreen, setIsMobileScreen] = useState<boolean>(false);

  const defineNavigationLevel = useCallback(async () => {
    // const companyToken = await localStorage.getItem("company_auth_token");
    // setIsSuperAdminLevel(!companyToken);
  }, [])

  useEffect(() => {
    defineNavigationLevel();
  }, [defineNavigationLevel]);

  useEffect(() => {
    const token = usersApi.getAuthTokenForUsersInfo();
    if (token) {
      const decodedToken: any = jwtDecode(token as string);
      const orgUuid = decodedToken.current_membership_uuid;

      setCurrentUserUuid(decodedToken.uuid);

      if (orgUuid) {
        organizationsApi
          .getOrganization(orgUuid)
          .then((res) => {
            dispatch(setUserOrganizationInfo(res.data));
          })
          .catch((err) => {});
      }
    }

    authApi
      .isSuperAdmin()
      .then((res) => {
        setIsSuperAdmin(res.data.value);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    setIsMobileScreen(!!(width < 540));
  }, [width]);

  useEffect(() => {
    setUserInfo(userDetails);
  }, [userDetails]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const goToSuperAdminLevel = () => {
    navigate("/admin/home");
  };

  return (
    <>
      <AppBar
        position="absolute"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: customTheme.colors.colorsForegroundFgPrimary,
        }}
      >
        <Container maxWidth="xl" sx={{ padding: "0 24px" }}>
          <Toolbar disableGutters sx={{ height: "64px", display: "flex" }}>
            <Box
              component="a"
              // href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
              }}
            >
              <img src="/name_logo_negative.svg" width="230px" />
            </Box>
            <Box
              component="a"
              // href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                maxWidth: 300,
              }}
            >
              <img src="/name_logo_negative.svg" width="230px" />
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: "flex",
                marginLeft: "auto",
              }}
            >
              {isSuperAdmin && currentUserOrganizationInfo && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    ml: "auto",
                    mr: theme.spacing.spacingLg,
                    textTransform: "initial",
                    borderRadius: theme.radius.radiusMd,
                    color: theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
                    backgroundColor:
                      theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBg,

                    "&:hover": {
                      backgroundColor: theme.colors.colorsBackgroundBgActive,
                    },
                    position: isMobileScreen ? "fixed" : "relative",
                    bottom: isMobileScreen ? "10px" : "initial",
                    left: isMobileScreen ? "50%" : "initial",
                    transform: isMobileScreen ? "translateX(-50%)" : "initial",
                  }}
                  type="submit"
                  startIcon={
                    <CornerUpLeft
                      size="16"
                      color={theme.colors.colorsForegroundFgSecondary}
                      strokeWidth={2}
                    />
                  }
                  onClick={goToSuperAdminLevel}
                >
                  {t("superAdminButtonText")}
                </Button>
              )}
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  mr: customTheme.spacing.spacingLg,
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography
                  sx={{
                    ...customTheme.typography[".text-sm-semibold"],
                    color: customTheme.colors.colorsTextTextPrimaryOnBrand,
                  }}
                >
                  {`${userInfo?.firstName || ""} ${userInfo?.lastName || ""}`}
                </Typography>
                <Typography
                  sx={{
                    ...customTheme.typography[".text-xs-regular"],
                    color: customTheme.colors.colorsTextTextQuaternary,
                  }}
                >
                  {currentUserOrganizationInfo?.name || "No organization"}
                </Typography>
              </Box>
              <Tooltip title="Open user settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Admin" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px", minWidth: "240px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={() => {
                    setIsEditProfileDialogOpen(true);
                    handleCloseUserMenu();
                  }}
                  sx={{ minWidth: "240px" }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      ...customTheme.typography[".text-sm-medium"],
                      color: customTheme.colors.colorsTextTextSecondary,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <UserEdit
                      size="16"
                      style={{ marginRight: customTheme.spacing.spacingMd }}
                      strokeWidth={2}
                    />
                    {t("pages.editProfile.editProfileButtonText")}
                  </Typography>
                </MenuItem>
                <Divider
                  sx={{
                    borderColor: customTheme.colors.colorsBorderBorderSecondary,
                  }}
                />
                <MenuItem onClick={onLogout} sx={{ minWidth: "240px" }}>
                  <Typography
                    textAlign="center"
                    sx={{
                      ...customTheme.typography[".text-sm-medium"],
                      color: customTheme.colors.colorsTextTextSecondary,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Logout04
                      size="16"
                      style={{ marginRight: customTheme.spacing.spacingMd }}
                      strokeWidth={2}
                    />
                    {t("pages.logout.title")}
                  </Typography>
                </MenuItem>
              </Menu>
              <Box
                sx={{
                  flexGrow: 1,
                  ml: "5px",
                  display: { xs: "flex", md: "none" },
                }}
              >
                <IconButton
                  sx={{
                    ml: "auto",
                    backgroundColor:
                      customTheme.colors.componentColorsUtilityGrayBlueUtilityGrayBlue700,
                    borderRadius: "8px",
                  }}
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={setIsSideBarOpen}
                  color="inherit"
                >
                  <Menu02
                    size="24"
                    style={{
                      color: isSideBarOpen
                        ? customTheme.colors.colorsBorderBorderBrandSolid
                        : "#fff",
                    }}
                    strokeWidth={2}
                  />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {isEditProfileDialogOpen && (
        <EditProfileDialog
          dialogOpen={isEditProfileDialogOpen}
          userId={currentUserUuid!}
          userDetails={userInfo}
          setDialogOpen={setIsEditProfileDialogOpen}
          onAddCallback={() => {
            setIsEditProfileDialogOpen(false);
            const token = getAuthToken(navigate);
            fetch(`${process.env.REACT_APP_ESCLOUD_API_HOST}/api/v1/users/me`, {
              method: "GET",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${token}`,
              },
            })
              .then((res) =>
                res.json().then((userDetails) => {
                  setUserInfo(userDetails);
                }),
              )
              .catch((e) => {
                console.log("Error happened: " + e.toString());
              });
          }}
        />
      )}
      {/* <IconButton color="inherit">
        <Badge badgeContent={4} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton> */}
    </>
  );
};
