import React, { RefObject, useEffect } from "react";

type ClickOutsideHandler = (event: MouseEvent) => void;

export default function useClickOutside(
  elementRef: RefObject<HTMLElement | null>,
  callback: ClickOutsideHandler,
  excludeIds: string[] = []
) {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        elementRef.current &&
        !elementRef.current.contains(event.target as Node) &&
        !excludeIds.includes((event.target as HTMLElement).id)
      ) {
        callback(event);
      }
    };

    document.addEventListener("mouseup", handleOutsideClick);

    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [elementRef, callback, excludeIds]);
}
