import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { StoredParameter } from "../services/models/parameters/parameters";

export interface ParametersState {
    parametersForInstallation: {
        [key: string]: {
            edit?: StoredParameter;
            settings?: Array<StoredParameter>;
            trend?: Array<StoredParameter>;
        }
    }
}

const initialState: ParametersState = {
    parametersForInstallation: {}
};

type ActionParameterProps = { payload: { id: string, parameter: StoredParameter }, type: string }

export const parametersSlice = createSlice({
    name: "parameters",
    initialState,
    reducers: {
        setForEditParameter: (state, action: ActionParameterProps) => {
            state.parametersForInstallation[action.payload.id] = { ...state.parametersForInstallation[action.payload.id], edit: action.payload.parameter };
        },
        addSettingsParameter: (state, action: ActionParameterProps) => {
            const settings = state.parametersForInstallation[action.payload.id].settings;
            if (settings) {
                const isAlreadyAddedIndexParameter = settings.findIndex(p => p.name === action.payload.parameter.name);
                if (isAlreadyAddedIndexParameter > -1) {
                    settings[isAlreadyAddedIndexParameter] = action.payload.parameter;
                }else{
                    settings.push(action.payload.parameter);
                }
            } else {
                state.parametersForInstallation[action.payload.id] = { ...state.parametersForInstallation[action.payload.id], settings: [action.payload.parameter] };
            }
        },
        updateSettingsParameter: (state, action: ActionParameterProps) => {
            const settings = state.parametersForInstallation[action.payload.id].settings;
            if (settings) {
                const upParameterIndex = settings.findIndex((p) => p.name === action.payload.parameter.name);
                if (upParameterIndex > -1) {
                    settings[upParameterIndex] = action.payload.parameter;
                }
            }
        },
        deleteSettingsParameter: (state, action: ActionParameterProps) => {
            const settings = state.parametersForInstallation[action.payload.id].settings;
            if (settings) {
                const delParameterIndex = settings.findIndex((p) => p.name === action.payload.parameter.name);
                if (delParameterIndex > -1) {
                    settings.splice(delParameterIndex, 1);
                }
            }
        },
        deleteAllSettingsParameters: (state, action) => {
            state.parametersForInstallation[action.payload].settings = [];
        },
        addTrendParameter: (state, action: ActionParameterProps) => {
            const trend = state.parametersForInstallation[action.payload.id]?.trend;
            if (trend) {
                const isAlreadyAdded = trend.findIndex(p => p.name === action.payload.parameter.name) > -1;
                if (!isAlreadyAdded) {
                    trend.push(action.payload.parameter);
                }
            } else {
                state.parametersForInstallation[action.payload.id] = { ...state.parametersForInstallation[action.payload.id], trend: [action.payload.parameter] };
            }
        },
        deleteTrendParameter: (state, action: ActionParameterProps) => {
            const trend = state.parametersForInstallation[action.payload.id].trend;
            if (trend) {
                const delParameterIndex = trend.findIndex((p) => p.name === action.payload.parameter.name);
                if (delParameterIndex > -1) {
                    trend.splice(delParameterIndex, 1);
                }
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const { setForEditParameter, addSettingsParameter, addTrendParameter, deleteAllSettingsParameters, deleteSettingsParameter, deleteTrendParameter, updateSettingsParameter } = parametersSlice.actions;
export const selectEditParameter = (state: RootState, id: string) => state.parametersSlice.parametersForInstallation[id]?.edit;
export const selectSettingsParameters = (state: RootState, id: string) => state.parametersSlice.parametersForInstallation[id]?.settings;
export const selectTrendParameters = (state: RootState, id: string) => state.parametersSlice.parametersForInstallation[id]?.trend;

export default parametersSlice.reducer;
