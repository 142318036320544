import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NumberInput from "../ResidentialInstallationDetailsPage/SettingsPage/components/NumberInput/NumberInput";
import { Button, ButtonType } from "../../../components/Button/Button";
import useTheme from "../../../theme/hooks/useTheme";
import {
  parametersApi,
  useGetParameterDetailsQuery,
  useSetParameterValuesMutation,
} from "../../../store/services/parameters";
import { useAppDispatch, useTypedSelector } from "../../../store";
import {
  addSettingsParameter,
  selectEditParameter,
  setForEditParameter,
} from "../../../store/reducers/parametersSlice";
import { DisabledField } from "../PairingVerificationPage/DisabledField";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../../components/Snackbar/SnackbarContext";

type EditParameterProps = {
  connectivityId: string;
};

export default function EditParameter({ connectivityId }: EditParameterProps) {
  const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.adjustParameters" });
  const theme = useTheme();
  const { colors, spacing, typography } = theme;
  const dispatch = useAppDispatch();
  const showSnackbar = useSnackbar();

  const storedEditParameter = useTypedSelector((state) =>
    selectEditParameter(state, connectivityId),
  );

  const { currentData } = useGetParameterDetailsQuery(
    { connectivityId, parameterName: storedEditParameter?.name || "" },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      pollingInterval: 20000,
    },
  );
  const [setParameterValues] = useSetParameterValuesMutation();

  const [newValue, setNewValue] = useState<number | undefined>();

  useEffect(() => {
    setNewValue(storedEditParameter?.newValue);
  }, [storedEditParameter?.newValue]);

  const addToSettingsParameter = () => {
    if (storedEditParameter && newValue) {
      dispatch(
        addSettingsParameter({
          id: connectivityId,
          parameter: { name: storedEditParameter.name, newValue },
        }),
      );
    }
  };

  const applyParameter = () => {
    if (storedEditParameter && newValue) {
      setParameterValues({ connectivityId, parameters: { [storedEditParameter.name]: newValue } })
        .unwrap()
        .then(() => {
          dispatch(
            parametersApi.util.updateQueryData(
              "getParameterDetails",
              { connectivityId, parameterName: storedEditParameter.name },
              (draftDetails) => {
                draftDetails.value = newValue;
              },
            ),
          );
          dispatch(
            setForEditParameter({
              id: connectivityId,
              parameter: { ...storedEditParameter, newValue: undefined },
            }),
          );
          dispatch(
            addSettingsParameter({
              id: connectivityId,
              parameter: { ...storedEditParameter, newValue: undefined },
            }),
          );
          showSnackbar("Your parameter set has been successfully submitted. ", {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 3000,
            severity: "success",
          });
        })
        .catch(() => {
          showSnackbar("An error occurred while submitting your parameter set. ", {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 3000,
            severity: "error",
          });
        });
    }
  };

  if (!storedEditParameter) {
    return null;
  }

  return (
    <>
      <Grid item xs={12}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                pb: spacing.spacingXl,
                borderBottom: `solid 1px ${colors.colorsBorderBorderSecondary}`,
                mb: spacing.spacingXl,
              }}
            >
              <Typography
                sx={{
                  ...typography[".text-sm-semibold"],
                  color: colors.colorsTextTextPrimary,
                }}
              >
                {currentData?.name || storedEditParameter?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <DisabledField
              id="minValue"
              value={currentData?.min ?? "--"}
              label={t("minValLabel")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DisabledField
              id="maxValue"
              value={currentData?.max ?? "--"}
              label={t("maxValLabel")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DisabledField
              id="currentValue"
              value={currentData?.value ?? "--"}
              label={t("currentValLabel")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NumberInput
              initialValue={newValue}
              changeValue={setNewValue}
              min={currentData?.min || undefined}
              max={currentData?.max || undefined}
              label={t("newValLabel") || ""}
              hideUnit
              hideControls
              inputWidth="100%"
              textAlign="left"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", gap: "12px" }}>
          <Button
            disabled={!newValue}
            buttonType={ButtonType.SecondaryColor}
            label={t("addToListBtn") || ""}
            onClick={addToSettingsParameter}
          />
          <Button
            disabled={!newValue}
            buttonType={ButtonType.Primary}
            label={t("applyNowBtn") || ""}
            onClick={applyParameter}
          />
        </Grid>
      </Grid>
    </>
  );
}
