import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { installationApi } from "../services/installation";
import { IInstallationDetails } from "../services/models/installations/installationsCommon";

export interface InstallationState {
  inProgressPairDevice: boolean;
  selectedInstallationDetails: IInstallationDetails | undefined;
}

const initialState: InstallationState = {
  inProgressPairDevice: false,
  selectedInstallationDetails: undefined,
};

export const installationSlice = createSlice({
  name: "installation",
  initialState,
  reducers: {
    setSelectedInstallationDetails: (state, action) => {
      state.selectedInstallationDetails = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
// export const { logout, clearLoginError, clearSignUpError, updateToken } = installationSlice.actions;

export const { setSelectedInstallationDetails } = installationSlice.actions;

export const selectSelectedInstallationDetails = (state: RootState) =>
  state.installationSlice.selectedInstallationDetails;

export const selectIsProgressPairing = (state: RootState) =>
  state.installationSlice.inProgressPairDevice;

export default installationSlice.reducer;
