import { api } from "./api";

export enum OrganizationTypes {
  DISTRIBUTOR = "DISTRIBUTOR",
  INSTALLER = "INSTALLER",
  COMMERCIAL_CLIENT = "COMMERCIAL_CLIENT",
  PRIVATE_CLIENT = "PRIVATE_CLIENT",
}

export interface IOrganizationDetails {
  uuid: string;
  parentUuid: string | null;
  name: string;
  country: string;
  address: string;
  phone: string | null;
  loggameraAccess: boolean;
  type: OrganizationTypes;
}

export const organizationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationDetails: build.query<IOrganizationDetails, { uuid: string }>(
      {
        query: ({ uuid }) => ({
          url: `organizations/${uuid}`,
        }),
      }
    ),
  }),
});

export const {
  useGetOrganizationDetailsQuery,
  useLazyGetOrganizationDetailsQuery,
  endpoints: { getOrganizationDetails },
} = organizationApi;
