import { Box, Typography } from "@mui/material";
import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";

export interface IAboutSectionProps {
  title: string;
  serialNumber?: string;
  model?: string;
  softwareVersion?: string;
  hardwareVersion?: string;
  eeprom?: string;
}

export default function AboutSection({
  title,
  eeprom,
  hardwareVersion,
  model,
  serialNumber,
  softwareVersion,
}: IAboutSectionProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.infoDetailsPage.about.labels",
  });

  return (
    <Box sx={{ width: "100%", mb: theme.spacing.spacing3xl }}>
      <Typography
        sx={{
          ...theme.typography[".text-lg-semibold"],
          color: theme.colors.colorsTextTextPrimary,
          mb: theme.spacing.spacingXl,
        }}
      >
        {title}
      </Typography>

      {serialNumber && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: theme.spacing.spacingXl,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-md-medium"],
              color: theme.colors.colorsTextTextTertiary,
              minWidth: "50%",
            }}
          >
            {t("serialNumber")}
          </Typography>
          <Typography
            sx={{
              ...theme.typography[".text-md-semibold"],
              color: theme.colors.colorsTextTextPrimary,
              wordBreak: "break-word",
            }}
          >
            {serialNumber}
          </Typography>
        </Box>
      )}
      {model && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: theme.spacing.spacingXl,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-md-medium"],
              color: theme.colors.colorsTextTextTertiary,
              minWidth: "50%",
            }}
          >
            {t("model")}
          </Typography>
          <Typography
            sx={{
              ...theme.typography[".text-md-semibold"],
              color: theme.colors.colorsTextTextPrimary,
              wordBreak: "break-word",
            }}
          >
            {model}
          </Typography>
        </Box>
      )}

      {softwareVersion && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: theme.spacing.spacingXl,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-md-medium"],
              color: theme.colors.colorsTextTextTertiary,
              minWidth: "50%",
            }}
          >
            {t("softwareVersion")}
          </Typography>
          <Typography
            sx={{
              ...theme.typography[".text-md-semibold"],
              color: theme.colors.colorsTextTextPrimary,
              wordBreak: "break-word",
            }}
          >
            {softwareVersion}
          </Typography>
        </Box>
      )}

      {hardwareVersion && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: theme.spacing.spacingXl,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-md-medium"],
              color: theme.colors.colorsTextTextTertiary,
              minWidth: "50%",
            }}
          >
            {t("hardwareVersion")}
          </Typography>
          <Typography
            sx={{
              ...theme.typography[".text-md-semibold"],
              color: theme.colors.colorsTextTextPrimary,
              wordBreak: "break-word",
            }}
          >
            {hardwareVersion}
          </Typography>
        </Box>
      )}

      {eeprom && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: theme.spacing.spacingXl,
          }}
        >
          <Typography
            sx={{
              ...theme.typography[".text-md-medium"],
              color: theme.colors.colorsTextTextTertiary,
              minWidth: "50%",
            }}
          >
            {t("eeprom")}
          </Typography>
          <Typography
            sx={{
              ...theme.typography[".text-md-semibold"],
              color: theme.colors.colorsTextTextPrimary,
              wordBreak: "break-word",
            }}
          >
            {eeprom}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
