import { Box, SxProps, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TabsNavigation, { ITabData } from "../../../components/TabsNavigation/TabsNavigation";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router";
import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import RealTimeData from "./RealTimeData/RealTimeData";
import useTheme from "../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { FleetManagementAPI } from "../../../api/FleetManagementAPI";
import { Status } from "../../../api/responses/fleetManagementResponses/FleetManagementResponse";
import { ArrowLeft } from "untitledui-js-base";
import SettingsPage from "./SettingsPage/SettingsPage";
import AnalyticsPage from "./AnalyticsPage/AnalyticsPage";
import InfoDetailsPage from "./InfoDetailsPage/InfoDetailsPage";
import {
  useGetInstallationDetailsQuery,
  useLazyGetInstallationAlertsHistoryQuery,
} from "../../../store/services/installation";
import {
  AlertType,
  OnlineStatus,
} from "../../../store/services/models/installations/installationsCommon";
import AlertsPage from "./AlertsPage/AlertsPage";
// import { SupportForm } from "./SupportForm/SupportForm";
import { useAppDispatch, useTypedSelector } from "../../../store";
import { selectUserOrganizationInfo } from "../../../store/reducers/organizationSlice";
import { setSelectedInstallationDetails } from "../../../store/reducers/installationSlice";
import { formatDate } from "../../../util/DateUtil";

export default function ResidentialInstallationDetailPage({
  isSuperAdminLevel = false,
}: {
  isSuperAdminLevel?: boolean;
}) {
  const theme = useTheme();
  const { typography, spacing, colors } = theme;
  const labelBaseStyles: SxProps = {
    ...typography[".text-xs-medium"],
    height: "22px",
    padding: `${spacing.spacingXxs} ${spacing.spacingMd} ${spacing.spacingXxs} ${spacing.spacingSm}`,
    borderRadius: "10px",
    border: `1px solid ${colors.componentColorsUtilityGrayUtilityGray600}`,
    color: colors.componentColorsUtilityGrayUtilityGray600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const dotStyles: SxProps = {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: colors.componentColorsUtilityBrandUtilityBrand600,
    mr: spacing.spacingSm,
  };

  const { t } = useTranslation("cloud_ui");

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const currentUserOrganizationInfo = useTypedSelector(selectUserOrganizationInfo);

  const baseUrl = `/${isSuperAdminLevel ? 'admin': 'portal'}/residential-installations/${id}`;

  const {
    data: installationDetails,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchDetails,
  } = useGetInstallationDetailsQuery(
    {
      installationUuid: id!,
      organizationUuid: currentUserOrganizationInfo?.uuid,
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 60000,
    },
  );

  const [trigger, { data: alerts, error, isLoading, isFetching }] =
    useLazyGetInstallationAlertsHistoryQuery();

  const [tabs, setTabs] = useState<Array<ITabData>>();
  const [installationInfo, setInstallationInfo] = useState<{
    name: string;
    location: string;
    status: OnlineStatus;
  }>();

  const [alertsCount, setAlertsCount] = useState<number>();

  const getInstallationAlerts = () => {
    trigger({
      installationUuid: id!,
      activeOnly: true,
      alertCode: [],
      pageNum: 0,
      pageSize: 300,
      startTimestamp: "",
      endTimestamp: "",
      type: [AlertType.ERROR, AlertType.WARNING],
    })
      .then(({ data }) => {
        if (data) {
          setAlertsCount(data.pagination.totalCount);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (location.pathname.includes("settings") || location.pathname.includes("real-time-data")) {
      refetchDetails();
    }
  }, [location.pathname, refetchDetails]);

  useEffect(() => {
    dispatch(setSelectedInstallationDetails(installationDetails));
  }, [installationDetails, installationDetails?.onlineStatus]);

  useEffect(() => {
    if (id) {
      setTabs([
        {
          href: `${baseUrl}/real-time-data${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.realTimeData"),
        },
        {
          href: `${baseUrl}/settings${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.settings"),
        },
        {
          href: `${baseUrl}/alerts${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.alerts"),
        },
        {
          href: `${baseUrl}/analytics${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.analytics"),
        },
        {
          href: `${baseUrl}/info-details${location.search}`,
          label: t("pages.residentialInstallation.details.tabs.infoDetails"),
        },
      ]);
      getInstallationAlerts();
    }
  }, [id]);

  useEffect(() => {
    if (installationDetails) {
      setInstallationInfo({
        location: installationDetails.location,
        name: installationDetails.name,
        status: installationDetails.onlineStatus,
      });
    }
  }, [installationDetails]);

  return (
    <Box
      sx={{
        display: "flex",
        padding: `${theme.spacing.spacing2xl} ${theme.spacing.spacing2xl} 0`,
        flexDirection: "column",
        backgroundColor: theme.colors.colorsBackgroundBgPrimary,
        height: "calc(100vh - 64px)",
        overflowY: "auto",
      }}
    >
      <Box sx={{ mb: theme.spacing.spacingXl, display: "flex" }}>
        <Box
          component={"button"}
          sx={{
            backgroundColor: "initial",
            height: "56px",
            width: "56px",
            border: `1px solid ${theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBorder}`,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: theme.colors.colorsBackgroundBgSecondaryHover,
            },
          }}
          onClick={() =>
            navigate(`/${isSuperAdminLevel ? "admin" : "portal"}/residential-installations`)
          }
        >
          <ArrowLeft
            style={{
              color: theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
            }}
            size="18"
            strokeWidth={2}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: theme.spacing.spacingLg,
          }}
        >
          <Box
            sx={{
              mb: theme.spacing.spacingXs,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                ...theme.typography[".display-xs-semibold"],
                color: theme.colors.colorsTextTextPrimary,
                mr: theme.spacing.spacingLg,
              }}
            >
              {installationInfo?.name || installationDetails?.connectivityId || ""}
            </Typography>
            {installationInfo?.status === OnlineStatus.ONLINE ? (
              <Box
                sx={{
                  ...labelBaseStyles,
                  color: colors.componentColorsUtilityBrandUtilityBrand600,
                  borderColor: colors.componentColorsUtilityBrandUtilityBrand600,
                }}
              >
                <Box
                  sx={{
                    ...dotStyles,
                  }}
                ></Box>
                {t("online_statuses.online")}
              </Box>
            ) : (
              <Box sx={{ ...labelBaseStyles }}>{t("online_statuses.offline")}</Box>
            )}
          </Box>
          <Typography
            sx={{
              ...theme.typography[".text-md-regular"],
              color: theme.colors.colorsTextTextTertiary,
            }}
          >
            {installationInfo?.location || ""}
          </Typography>
          {
            <Typography
              sx={{
                ...theme.typography[".text-md-regular"],
                color: theme.colors.colorsTextTextTertiary,
              }}
            >
              {`${t("pages.residentialInstallation.details.lastComunicationLabel")} ${
                installationDetails?.lastCommunicationDate
                  ? formatDate(installationDetails?.lastCommunicationDate)
                  : "--"
              }`}
            </Typography>
          }
        </Box>
        {/* <Box sx={{ ml: "auto" }}>
          <SupportForm />
        </Box> */}
      </Box>
      <Box sx={{ mb: theme.spacing.spacingLg }}>
        <TabsNavigation tabs={tabs} alertsCount={alertsCount} />
      </Box>

      <Routes>
        <Route
          path="/real-time-data"
          element={<PrivateRoute element={<RealTimeData />} rolesToCheck={["INSTALLATION_READ"]} />}
        ></Route>
        <Route
          path="/settings/*"
          element={
            <PrivateRoute
              element={<SettingsPage baseUrl={`${baseUrl}/settings`} />}
              rolesToCheck={["INSTALLATION_READ"]}
            />
          }
        ></Route>
        <Route
          path="/alerts"
          element={<PrivateRoute element={<AlertsPage />} rolesToCheck={["INSTALLATION_READ"]} />}
        ></Route>
        <Route
          path="/analytics"
          element={
            <PrivateRoute element={<AnalyticsPage />} rolesToCheck={["INSTALLATION_READ"]} />
          }
        ></Route>
        <Route
          path="/info-details"
          element={
            <PrivateRoute element={<InfoDetailsPage />} rolesToCheck={["INSTALLATION_READ"]} />
          }
        ></Route>
      </Routes>
    </Box>
  );
}
