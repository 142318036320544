import React from "react";
import useTheme from "../../theme/hooks/useTheme";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

export interface ISideMenuItem {
  label: string;
  href: string;
  icon?: any;
  hide?: boolean;
}

export interface ISideMenuProps {
  menuItems: Array<ISideMenuItem>;
}

export default function SimpleSideMenu({ menuItems }: ISideMenuProps) {
  const theme = useTheme();
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <Box>
      <List sx={{ padding: `0 ${theme.spacing.spacingMd}` }}>
        {menuItems.map(
          (menuItem, index) =>
            !menuItem.hide && (
              <ListItem
                button
                key={`${index}_menuItem`}
                component={RouterLink}
                to={menuItem.href}
                sx={{
                  ...theme.typography[".text-md-semibold"],
                  padding: theme.spacing.spacingMd,
                  color: theme.colors.colorsTextTextSecondary,
                  borderRadius: "10px",
                  minHeight: "40px",
                  mb: theme.spacing.spacingXs,
                  ".MuiListItemIcon-root": {
                    color: currentPath.includes(menuItem.href.split("?")[0])
                      ? theme.colors.colorsTextTextBrandTertiaryAlt
                      : "inherit",
                  },
                  ".MuiTypography-root": {
                    color: currentPath.includes(menuItem.href.split("?")[0])
                      ? theme.colors.colorsTextTextBrandTertiaryAlt
                      : "inherit",
                  },
                  backgroundColor: currentPath.includes(menuItem.href.split("?")[0])
                    ? theme.colors.colorsBackgroundBgActive
                    : "inital",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "fit-content",
                    mr: theme.spacing.spacingLg,
                  }}
                >
                  {menuItem.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ...theme.typography[".text-md-semibold"],
                    color: theme.colors.colorsTextTextSecondary,
                    fontWeight: "600 !important",
                  }}
                  primary={menuItem.label}
                  primaryTypographyProps={{
                    style: {
                      fontWeight: 600,
                    },
                  }}
                />
              </ListItem>
            ),
        )}
      </List>
    </Box>
  );
}
