import { SxProps } from "@mui/material";
import { ESThemeType } from "../../theme/hooks/useTheme";

interface IGetDropdownWrapperStylesTypeParameters {
  theme: ESThemeType;
  buttonCoords: DOMRect | undefined;
  dropdownCoords: DOMRect | undefined;
}

type getDropdownWrapperStylesType = ({
  theme,
  buttonCoords,
  dropdownCoords,
}: IGetDropdownWrapperStylesTypeParameters) => SxProps;

export const getDropdownWrapperStyles: getDropdownWrapperStylesType = ({
  theme,
  buttonCoords,
  dropdownCoords,
}) => {
  return {
    position: "fixed",
    border: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
    borderRadius: theme.radius.radiusMd,
    p: theme.spacing.spacingSm,
    backgroundColor:
      theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);",
    minHeight: "60px",
    maxHeight: "400px",
    overflow: "hidden",
    left: `calc(${buttonCoords?.left}px - ${dropdownCoords?.width}px + ${buttonCoords?.width}px)`,
    top: `calc(${buttonCoords?.top}px + 45px)`,
  };
};

export const getSectionTitleStyles = (theme: ESThemeType): SxProps => {
  return {
    ...theme.typography[".text-sm-semibold"],
    color: theme.colors.colorsTextTextPrimary,
    ml: theme.spacing.spacingXl,
    height: "40px",
    display: "flex",
    alignItems: "center",
  };
};
