import React from "react";
import { StoredParameter } from "../../../store/services/models/parameters/parameters";
import useTheme, { ESThemeType } from "../../../theme/hooks/useTheme";
import { Box, Checkbox, FormControlLabel, Grid, SxProps, Typography } from "@mui/material";
import { useAppDispatch } from "../../../store";
import { useGetParameterDetailsQuery } from "../../../store/services/parameters";
import { Button, ButtonType } from "../../../components/Button/Button";
import { setForEditParameter } from "../../../store/reducers/parametersSlice";
import { useTranslation } from "react-i18next";

type SettingsParameterItemProps = {
  connectivityId: string;
  parameter: StoredParameter;
  onSelect: (checked: boolean, identifier: string) => void;
  isSelected: boolean;
};

export default function SettingsParameterItem({
  connectivityId,
  parameter,
  onSelect,
  isSelected,
}: SettingsParameterItemProps) {
  const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.adjustParameters" });
  const theme = useTheme();
  const { colors, typography } = theme;
  const styles = getStyles(theme);
  const dispatch = useAppDispatch();

  const { currentData } = useGetParameterDetailsQuery(
    { connectivityId, parameterName: parameter.name },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      pollingInterval: 20000,
    },
  );

  const addToEdit = () => {
    dispatch(
      setForEditParameter({
        id: connectivityId,
        parameter: { name: parameter.name, newValue: parameter.newValue },
      }),
    );
  };

  return (
    <Grid
      item
      sx={styles.settingsItemContainerStyles}
      className={parameter.isSelected ? "selected" : ""}
      xs={12}
    >
      <Box sx={styles.headerContainerStyle}>
        <FormControlLabel
          disableTypography
          sx={styles.checkBoxContainerStyle}
          control={
            <Checkbox
              sx={styles.checkBoxStyle}
              size="small"
              checked={isSelected}
              onChange={(_e, checked) => {
                onSelect(checked, parameter.name);
              }}
            />
          }
          label={<Typography sx={styles.checkBoxLabelStyle}>{parameter.name}</Typography>}
        />
        <Button buttonType={ButtonType.LinkColor} label={t("editBtn") || ""} onClick={addToEdit} />
      </Box>
      <Box sx={styles.settingWrapperStyles}>
        <Box sx={styles.settingsRowStyles}>
          <Box sx={styles.settingsRowLabel}>
            <Typography
              sx={{
                ...typography[".text-sm-regular"],
                color: colors.colorsTextTextPrimary,
              }}
            >
              {parameter.newValue == null ? t("setValLabel") : t("currentValLabel")}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                ...typography[".text-sm-medium"],
                color: colors.colorsTextTextPrimary,
              }}
            >
              {currentData?.value ?? "--"}
            </Typography>
          </Box>
        </Box>
        {parameter.newValue != null && (
          <Box sx={styles.settingsRowStyles}>
            <Box sx={styles.settingsRowLabel}>
              <Typography
                sx={{
                  ...typography[".text-sm-semibold"],
                  color: colors.colorsTextTextPrimary,
                }}
              >
                {t("newValLabel")}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  ...typography[".text-sm-medium"],
                  color: colors.colorsTextTextPrimary,
                }}
              >
                {parameter.newValue}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  );
}

const getStyles = ({ colors, spacing, typography, radius }: ESThemeType) => {
  return {
    settingsItemContainerStyles: {
      padding: `${spacing.spacingLg} ${spacing.spacingXl}`,
      border: `solid 1px ${colors.colorsBorderBorderSecondary}`,
      borderRadius: radius.radiusSm,
      "&.selected": {
        borderColor: colors.colorsBorderBorderBrandSolid,
      },
    },

    headerContainerStyle: {
      display: "flex",
      flexDirection: "row",
      alignItems: "start",
      mb: spacing.spacingMd,
    } as SxProps,

    checkBoxContainerStyle: {
      display: "flex",
      flex: 1,
      mr: 0,
    } as SxProps,

    checkBoxStyle: {
      "&.Mui-checked": {
        color: colors.colorsBackgroundBgBrandSolid,
      },
    } as SxProps,

    checkBoxLabelStyle: {
      ...typography[".text-sm-semibold"],
      color: colors.colorsTextTextPrimary,
      flex: 1,
      pr: spacing.spacingXl,
      wordBreak: "break-all",
    } as SxProps,

    settingWrapperStyles: {
      display: "flex",
      flexDirection: "column",
      gap: spacing.spacingMd,
      ml: "26px",
    } as SxProps,

    settingsRowStyles: {
      display: "flex",
      flex: 1,
    } as SxProps,

    settingsRowLabel: {
      display: "flex",
      flex: 1,
      pr: spacing.spacingXl,
    } as SxProps,
  };
};
