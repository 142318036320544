import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import React from "react";
import { XClose } from "untitledui-js-base";
import useTheme from "../../../theme/hooks/useTheme";
import { Trans, useTranslation } from "react-i18next";
import { Circle } from "@mui/icons-material";

export interface IAddResidentialInstallationModalProps {
  isOpen: boolean;
  onCancel: () => void;
}

export const AddResidentialInstallationModal = ({
  isOpen,
  onCancel,
}: IAddResidentialInstallationModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      sx={{ "& .MuiPaper-root": { maxWidth: "90vw", width: "912px" } }}
    >
      <DialogTitle
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: theme.spacing.spacing4xl,
          padding: `${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl} 0`,
          position: "relative",
        }}
      >
        <Typography
          sx={{
            ...theme.typography[".text-lg-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingLg,
          }}
        >
          {t("pages.residentialInstallation.addInstallationModal.title")}
        </Typography>

        <Typography
          sx={{
            ...theme.typography[".text-sm-regular"],
            color: theme.colors.colorsTextTextTertiary,
          }}
        >
          {t("pages.residentialInstallation.addInstallationModal.description")}
        </Typography>
        <Box
          component={"button"}
          onClick={onCancel}
          sx={{
            border: "none",
            backgroundColor: "initial",
            display: "flex",
            padding: "0",
            cursor: "pointer",
            position: "absolute",
            top: "20px",
            right: "20px",
          }}
        >
          <XClose
            size="30"
            style={{
              color: theme.colors.colorsForegroundFgQuinary,
            }}
            strokeWidth={2}
          />
        </Box>
      </DialogTitle>

      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        {/* HEAT PUMP STEPS */}
        <Typography
          sx={{
            ...theme.typography[".text-sm-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingMd,
          }}
        >
          {t(
            "pages.residentialInstallation.addInstallationModal.heatPumpDisplaySteps.title"
          )}
        </Typography>
        <List sx={{ mb: theme.spacing.spacingMd }}>
          <ListItem sx={{ p: theme.spacing.spacingXs }}>
            <ListItemIcon
              sx={{ minWidth: "initial", mr: theme.spacing.spacingSm }}
            >
              <Circle
                sx={{
                  fontSize: 8,
                  color: theme.colors.colorsTextTextTertiary,
                }}
              />
            </ListItemIcon>
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextTertiary,
              }}
            >
              {t(
                "pages.residentialInstallation.addInstallationModal.heatPumpDisplaySteps.step1"
              )}
            </Typography>
          </ListItem>
          <ListItem sx={{ p: theme.spacing.spacingXs }}>
            <ListItemIcon
              sx={{ minWidth: "initial", mr: theme.spacing.spacingSm }}
            >
              <Circle
                sx={{
                  fontSize: 8,
                  color: theme.colors.colorsTextTextTertiary,
                }}
              />
            </ListItemIcon>
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextTertiary,
              }}
            >
              <Trans
                ns="cloud_ui"
                i18nKey="pages.residentialInstallation.addInstallationModal.heatPumpDisplaySteps.step2"
                components={{
                  b: <b />,
                }}
              />
              {/* {t(
                "pages.residentialInstallation.addInstallationModal.heatPumpDisplaySteps.step2"
              )} */}
            </Typography>
          </ListItem>
        </List>
        {/* HEAT PUMP STEPS ENDS*/}
        {/* SCAN QR STEPS */}
        <Typography
          sx={{
            ...theme.typography[".text-sm-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingMd,
          }}
        >
          {t(
            "pages.residentialInstallation.addInstallationModal.scanQRCodeSteps.title"
          )}
        </Typography>
        <List sx={{ mb: theme.spacing.spacingMd }}>
          <ListItem sx={{ p: theme.spacing.spacingXs }}>
            <ListItemIcon
              sx={{ minWidth: "initial", mr: theme.spacing.spacingSm }}
            >
              <Circle
                sx={{
                  fontSize: 8,
                  color: theme.colors.colorsTextTextTertiary,
                }}
              />
            </ListItemIcon>
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextTertiary,
              }}
            >
              {t(
                "pages.residentialInstallation.addInstallationModal.scanQRCodeSteps.step1"
              )}
            </Typography>
          </ListItem>
          <ListItem sx={{ p: theme.spacing.spacingXs }}>
            <ListItemIcon
              sx={{ minWidth: "initial", mr: theme.spacing.spacingSm }}
            >
              <Circle
                sx={{
                  fontSize: 8,
                  color: theme.colors.colorsTextTextTertiary,
                }}
              />
            </ListItemIcon>
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextTertiary,
              }}
            >
              {t(
                "pages.residentialInstallation.addInstallationModal.scanQRCodeSteps.step2"
              )}
            </Typography>
          </ListItem>
        </List>
        {/* SCAN QR STEPS ENDS*/}
        {/* LOG IN STEPS */}
        <Typography
          sx={{
            ...theme.typography[".text-sm-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingMd,
          }}
        >
          {t(
            "pages.residentialInstallation.addInstallationModal.logInSteps.title"
          )}
        </Typography>
        <List sx={{ mb: theme.spacing.spacingMd }}>
          <ListItem sx={{ p: theme.spacing.spacingXs }}>
            <ListItemIcon
              sx={{ minWidth: "initial", mr: theme.spacing.spacingSm }}
            >
              <Circle
                sx={{
                  fontSize: 8,
                  color: theme.colors.colorsTextTextTertiary,
                }}
              />
            </ListItemIcon>
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextTertiary,
              }}
            >
              {t(
                "pages.residentialInstallation.addInstallationModal.logInSteps.step1"
              )}
            </Typography>
          </ListItem>
          <ListItem sx={{ p: theme.spacing.spacingXs }}>
            <ListItemIcon
              sx={{ minWidth: "initial", mr: theme.spacing.spacingSm }}
            >
              <Circle
                sx={{
                  fontSize: 8,
                  color: theme.colors.colorsTextTextTertiary,
                }}
              />
            </ListItemIcon>
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextTertiary,
              }}
            >
              {t(
                "pages.residentialInstallation.addInstallationModal.logInSteps.step2"
              )}
            </Typography>
          </ListItem>
        </List>
        {/* LOG IN STEPS ENDS*/}
        <Typography
          sx={{
            ...theme.typography[".text-sm-regular"],
            color: theme.colors.colorsTextTextTertiary,
          }}
        >
          {t("pages.residentialInstallation.addInstallationModal.finalHint")}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
