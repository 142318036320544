import { Button, Container, Grid, TableCell, Typography } from "@mui/material";
import React, { CSSProperties, LegacyRef, useRef } from "react";
import useTheme from "../../theme/hooks/useTheme";
import SortableTable, { Column } from "../../components/Grid/SortableTable";
import { useTranslation } from "react-i18next";
import {
  useGetOrganizationQuery,
  useGetPartnerShipsQuery,
} from "../../store/services/organizations";
import { useNavigate } from "react-router";
import { UsersAPI } from "../../api/UsersAPI";
import { jwtDecode } from "jwt-decode";
import { RelatedCompanyResponse } from "../../store/services/models/organizations/organizations";
import { Plus } from "untitledui-js-base";
import AddOrganizationDialog, { AddOrganizationDialogRef } from "./AddOrganizationDialog";
import { OrganizationTypes } from "../../api/OrganizationsAPI";

export default function OrganizationsListPage() {
  const theme = useTheme();
  const pendingUserStyle: CSSProperties = {
    borderRadius: "15px",
    border: `1px solid ${theme.colors.componentColorsUtilityWarningUtilityWarning200}`,
    backgroundColor: theme.colors.componentColorsUtilityWarningUtilityWarning50,
    color: theme.colors.componentColorsUtilityWarningUtilityWarning700,
    padding: "2px 8px",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "18px",
    display: "flex",
    whiteSpace: "nowrap",
    maxHeight: "20px",
    alignItems: "center",
    maxWidth: "max-content",
  };

  const activeUserStyle: CSSProperties = {
    ...pendingUserStyle,
    border: `1px solid ${theme.colors.componentColorsUtilitySuccessUtilitySuccess200}`,
    backgroundColor: theme.colors.componentColorsUtilitySuccessUtilitySuccess50,
    color: theme.colors.componentColorsUtilitySuccessUtilitySuccess700,
  };

  const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.company_organizations" });
  const { t: tOrgTypes } = useTranslation("cloud_ui", { keyPrefix: "pages.organizations_types" });
  const { t: tStatuses } = useTranslation("cloud_ui", { keyPrefix: "statuses" });
  const navigate = useNavigate();
  const usersApi = new UsersAPI(navigate);

  const addOrganizationDialogRef = useRef<AddOrganizationDialogRef>(null);

  const getOrgUuid = () => {
    const token = usersApi.getAuthTokenForUsersInfo();
    const decodedToken: any = jwtDecode(token as string);
    return decodedToken.current_membership_uuid;
  };

  const orgUuid = getOrgUuid();

  const { data, refetch } = useGetPartnerShipsQuery(
    { uuid: orgUuid },
    { refetchOnMountOrArgChange: true },
  );
  const { data: currentOrg } = useGetOrganizationQuery({ uuid: orgUuid });

  const renderUserState = (status: RelatedCompanyResponse["status"]) => {
    switch (status) {
      case "ACTIVE":
        return <span style={activeUserStyle}>{tStatuses("active")}</span>;
      case "PENDING":
        return <span style={pendingUserStyle}>{tStatuses("pending")}</span>;
      default:
        return <></>;
    }
  };

    const renderOrgType = (type: RelatedCompanyResponse["type"]) => {
      switch (type) {
        case OrganizationTypes.COMMERCIAL_CLIENT:
          return tOrgTypes("client");
        case OrganizationTypes.INSTALLER:
          return tOrgTypes("installer");
        case OrganizationTypes.DISTRIBUTOR:
          return tOrgTypes("distributor");
        default:
          return type;
      }
    };

  const getParentsRows = () => {
    return (
      data?.parents.map((item) => {
        return {
          ...item,
          isRowHighlited: item.status === "PENDING",
          name: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-medium"],
                color: theme.colors.colorsTextTextPrimary,
                minHeight: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {`${item.name}`}
            </Typography>
          ),
          type: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextSecondary,
                minHeight: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {renderOrgType(item.type)}
            </Typography>
          ),
          status: renderUserState(item.status || "ACTIVE"),
        };
      }) || []
    );
  };

  const getChildRows = () => {
    const hasParents = Boolean(data?.parents?.length);
    return (
      data?.children.map((item) => {
        return {
          ...item,
          isRowHighlited: item.status === "PENDING",
          name: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-medium"],
                color: theme.colors.colorsTextTextPrimary,
                minHeight: "40px",
                display: "flex",
                alignItems: "center",
                paddingLeft: hasParents ? "40px" : theme.spacing.spacing3xl,
              }}
            >
              {"↳ "}
              {`${item.name}`}
            </Typography>
          ),
          type: (
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextSecondary,
                minHeight: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {renderOrgType(item.type)}
            </Typography>
          ),
          status: renderUserState(item.status || "ACTIVE"),
        };
      }) || []
    );
  };

  const getMyRow = () => {
    const hasParents = Boolean(data?.parents?.length);
    return {
      ...currentOrg,
      name: (
        <Typography
          sx={{
            ...theme.typography[".text-sm-semibold"],
            color: theme.colors.colorsTextTextBrandSecondary,
            minHeight: "40px",
            display: "flex",
            alignItems: "center",
            paddingLeft: hasParents ? theme.spacing.spacing3xl : 0,
          }}
        >
          {hasParents && "↳ "}
          {`${currentOrg?.name} (${t("label_my")})`}
        </Typography>
      ),
      type: currentOrg?.type && (
        <Typography
          sx={{
            ...theme.typography[".text-sm-regular"],
            color: theme.colors.colorsTextTextSecondary,
            minHeight: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {renderOrgType(currentOrg.type)}
        </Typography>
      ),
      status: renderUserState("ACTIVE"),
    };
  };

  const tableColumns: Column[] = [
    { id: "uuid", label: "", hidden: true },
    {
      id: "name",
      label: t("table.titles.name"),
      disableSorting: true,
    },
    {
      id: "type",
      label: t("table.titles.type"),
      disableSorting: true,
    },
    {
      id: "address",
      label: t("table.titles.address"),
      disableSorting: true,
    },
    {
      id: "phone",
      label: t("table.titles.phone"),
      disableSorting: true,
    },
    {
      id: "status",
      label: t("table.titles.status"),
      disableSorting: true,
    },
  ];

  const renderInfoTableHeadContent = () => {
    const pendingCount = [...(data?.children || []), ...(data?.parents || [])]?.filter(
      (el) => el.status === "PENDING",
    ).length;
    return (
      <TableCell colSpan={5}>
        <Grid container alignItems={"center"}>
          <Grid container xs={6} alignItems={"center"}>
            <Typography
              sx={{
                ...theme.typography[".text-xl-semibold"],
                paddingRight: theme.spacing.spacingSm,
              }}
            >
              {t("title")}
            </Typography>
            {pendingCount > 0 && (
              <Typography
                sx={{
                  ...pendingUserStyle,
                  ...theme.typography[".text-xs-medium"],
                }}
              >
                {t("title_status", { count: pendingCount })}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                textTransform: "initial",
                borderRadius: theme.radius.radiusMd,
                backgroundColor:
                  theme.colors.componentColorsComponentsButtonsPrimaryButtonPrimaryBg,
              }}
              type="submit"
              startIcon={
                <Plus
                  size="16"
                  color={theme.colors.componentColorsComponentsButtonsPrimaryButtonPrimaryFg}
                  strokeWidth={2}
                />
              }
              onClick={() => addOrganizationDialogRef.current?.open()}
            >
              {t("btn_add_organization")}
            </Button>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  return (
    <Container style={{ marginTop: theme.spacing.spacing3xl }}>
      <Typography
        sx={{
          ...theme.typography[".display-sm-semibold"],
          paddingBottom: theme.spacing.spacing2xl,
        }}
      >
        {t("page_title")}
      </Typography>
      <SortableTable
        columns={tableColumns}
        rows={[...getParentsRows(), getMyRow(), ...getChildRows()]}
        infoTableHeadContent={renderInfoTableHeadContent()}
      />
      <AddOrganizationDialog
        organizationUuid={orgUuid}
        ref={addOrganizationDialogRef as LegacyRef<AddOrganizationDialogRef>}
        onSuccess={refetch}
      />
    </Container>
  );
}
